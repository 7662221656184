/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Operations from './query';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export function useCaseRejectReasonsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CaseRejectReasonsQuery, import('./api').CaseRejectReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseRejectReasonsQuery, import('./api').CaseRejectReasonsQueryVariables>(Operations.CaseRejectReasons, options);
      }
export function useCaseRejectReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseRejectReasonsQuery, import('./api').CaseRejectReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseRejectReasonsQuery, import('./api').CaseRejectReasonsQueryVariables>(Operations.CaseRejectReasons, options);
        }
export type CaseRejectReasonsQueryHookResult = ReturnType<typeof useCaseRejectReasonsQuery>;
export type CaseRejectReasonsLazyQueryHookResult = ReturnType<typeof useCaseRejectReasonsLazyQuery>;
export function useCaseRejectReasonQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseRejectReasonQuery, import('./api').CaseRejectReasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseRejectReasonQuery, import('./api').CaseRejectReasonQueryVariables>(Operations.CaseRejectReason, options);
      }
export function useCaseRejectReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseRejectReasonQuery, import('./api').CaseRejectReasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseRejectReasonQuery, import('./api').CaseRejectReasonQueryVariables>(Operations.CaseRejectReason, options);
        }
export type CaseRejectReasonQueryHookResult = ReturnType<typeof useCaseRejectReasonQuery>;
export type CaseRejectReasonLazyQueryHookResult = ReturnType<typeof useCaseRejectReasonLazyQuery>;
export function useFormTagsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').FormTagsQuery, import('./api').FormTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FormTagsQuery, import('./api').FormTagsQueryVariables>(Operations.FormTags, options);
      }
export function useFormTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FormTagsQuery, import('./api').FormTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FormTagsQuery, import('./api').FormTagsQueryVariables>(Operations.FormTags, options);
        }
export type FormTagsQueryHookResult = ReturnType<typeof useFormTagsQuery>;
export type FormTagsLazyQueryHookResult = ReturnType<typeof useFormTagsLazyQuery>;
export function useFormTagQuery(baseOptions: Apollo.QueryHookOptions<import('./api').FormTagQuery, import('./api').FormTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FormTagQuery, import('./api').FormTagQueryVariables>(Operations.FormTag, options);
      }
export function useFormTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FormTagQuery, import('./api').FormTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FormTagQuery, import('./api').FormTagQueryVariables>(Operations.FormTag, options);
        }
export type FormTagQueryHookResult = ReturnType<typeof useFormTagQuery>;
export type FormTagLazyQueryHookResult = ReturnType<typeof useFormTagLazyQuery>;
export function usePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PermissionsQuery, import('./api').PermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PermissionsQuery, import('./api').PermissionsQueryVariables>(Operations.Permissions, options);
      }
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PermissionsQuery, import('./api').PermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PermissionsQuery, import('./api').PermissionsQueryVariables>(Operations.Permissions, options);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export function useResolutionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ResolutionsQuery, import('./api').ResolutionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ResolutionsQuery, import('./api').ResolutionsQueryVariables>(Operations.Resolutions, options);
      }
export function useResolutionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ResolutionsQuery, import('./api').ResolutionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ResolutionsQuery, import('./api').ResolutionsQueryVariables>(Operations.Resolutions, options);
        }
export type ResolutionsQueryHookResult = ReturnType<typeof useResolutionsQuery>;
export type ResolutionsLazyQueryHookResult = ReturnType<typeof useResolutionsLazyQuery>;
export function useResolutionQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ResolutionQuery, import('./api').ResolutionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ResolutionQuery, import('./api').ResolutionQueryVariables>(Operations.Resolution, options);
      }
export function useResolutionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ResolutionQuery, import('./api').ResolutionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ResolutionQuery, import('./api').ResolutionQueryVariables>(Operations.Resolution, options);
        }
export type ResolutionQueryHookResult = ReturnType<typeof useResolutionQuery>;
export type ResolutionLazyQueryHookResult = ReturnType<typeof useResolutionLazyQuery>;
export function useRiskScoringTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').RiskScoringTemplatesQuery, import('./api').RiskScoringTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RiskScoringTemplatesQuery, import('./api').RiskScoringTemplatesQueryVariables>(Operations.RiskScoringTemplates, options);
      }
export function useRiskScoringTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RiskScoringTemplatesQuery, import('./api').RiskScoringTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RiskScoringTemplatesQuery, import('./api').RiskScoringTemplatesQueryVariables>(Operations.RiskScoringTemplates, options);
        }
export type RiskScoringTemplatesQueryHookResult = ReturnType<typeof useRiskScoringTemplatesQuery>;
export type RiskScoringTemplatesLazyQueryHookResult = ReturnType<typeof useRiskScoringTemplatesLazyQuery>;
export function useRiskScoringTemplateQuery(baseOptions: Apollo.QueryHookOptions<import('./api').RiskScoringTemplateQuery, import('./api').RiskScoringTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RiskScoringTemplateQuery, import('./api').RiskScoringTemplateQueryVariables>(Operations.RiskScoringTemplate, options);
      }
export function useRiskScoringTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RiskScoringTemplateQuery, import('./api').RiskScoringTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RiskScoringTemplateQuery, import('./api').RiskScoringTemplateQueryVariables>(Operations.RiskScoringTemplate, options);
        }
export type RiskScoringTemplateQueryHookResult = ReturnType<typeof useRiskScoringTemplateQuery>;
export type RiskScoringTemplateLazyQueryHookResult = ReturnType<typeof useRiskScoringTemplateLazyQuery>;
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').RolesQuery, import('./api').RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RolesQuery, import('./api').RolesQueryVariables>(Operations.Roles, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RolesQuery, import('./api').RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RolesQuery, import('./api').RolesQueryVariables>(Operations.Roles, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export function useRoleQuery(baseOptions: Apollo.QueryHookOptions<import('./api').RoleQuery, import('./api').RoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RoleQuery, import('./api').RoleQueryVariables>(Operations.Role, options);
      }
export function useRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RoleQuery, import('./api').RoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RoleQuery, import('./api').RoleQueryVariables>(Operations.Role, options);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export function useAccountStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AccountStatsQuery, import('./api').AccountStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AccountStatsQuery, import('./api').AccountStatsQueryVariables>(Operations.AccountStats, options);
      }
export function useAccountStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AccountStatsQuery, import('./api').AccountStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AccountStatsQuery, import('./api').AccountStatsQueryVariables>(Operations.AccountStats, options);
        }
export type AccountStatsQueryHookResult = ReturnType<typeof useAccountStatsQuery>;
export type AccountStatsLazyQueryHookResult = ReturnType<typeof useAccountStatsLazyQuery>;
export function useTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionsQuery, import('./api').TransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionsQuery, import('./api').TransactionsQueryVariables>(Operations.Transactions, options);
      }
export function useTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionsQuery, import('./api').TransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionsQuery, import('./api').TransactionsQueryVariables>(Operations.Transactions, options);
        }
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export function useTransactionQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionQuery, import('./api').TransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionQuery, import('./api').TransactionQueryVariables>(Operations.Transaction, options);
      }
export function useTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionQuery, import('./api').TransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionQuery, import('./api').TransactionQueryVariables>(Operations.Transaction, options);
        }
export type TransactionQueryHookResult = ReturnType<typeof useTransactionQuery>;
export type TransactionLazyQueryHookResult = ReturnType<typeof useTransactionLazyQuery>;
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').UsersQuery, import('./api').UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').UsersQuery, import('./api').UsersQueryVariables>(Operations.Users, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').UsersQuery, import('./api').UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').UsersQuery, import('./api').UsersQueryVariables>(Operations.Users, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export function useCheckupsUsersQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CheckupsUsersQuery, import('./api').CheckupsUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupsUsersQuery, import('./api').CheckupsUsersQueryVariables>(Operations.CheckupsUsers, options);
      }
export function useCheckupsUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupsUsersQuery, import('./api').CheckupsUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupsUsersQuery, import('./api').CheckupsUsersQueryVariables>(Operations.CheckupsUsers, options);
        }
export type CheckupsUsersQueryHookResult = ReturnType<typeof useCheckupsUsersQuery>;
export type CheckupsUsersLazyQueryHookResult = ReturnType<typeof useCheckupsUsersLazyQuery>;
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<import('./api').UserQuery, import('./api').UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').UserQuery, import('./api').UserQueryVariables>(Operations.User, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').UserQuery, import('./api').UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').UserQuery, import('./api').UserQueryVariables>(Operations.User, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export function useAccountSettingsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AccountSettingsQuery, import('./api').AccountSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AccountSettingsQuery, import('./api').AccountSettingsQueryVariables>(Operations.AccountSettings, options);
      }
export function useAccountSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AccountSettingsQuery, import('./api').AccountSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AccountSettingsQuery, import('./api').AccountSettingsQueryVariables>(Operations.AccountSettings, options);
        }
export type AccountSettingsQueryHookResult = ReturnType<typeof useAccountSettingsQuery>;
export type AccountSettingsLazyQueryHookResult = ReturnType<typeof useAccountSettingsLazyQuery>;
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AccountsQuery, import('./api').AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AccountsQuery, import('./api').AccountsQueryVariables>(Operations.Accounts, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AccountsQuery, import('./api').AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AccountsQuery, import('./api').AccountsQueryVariables>(Operations.Accounts, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export function useAccountQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AccountQuery, import('./api').AccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AccountQuery, import('./api').AccountQueryVariables>(Operations.Account, options);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AccountQuery, import('./api').AccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AccountQuery, import('./api').AccountQueryVariables>(Operations.Account, options);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export function useBlacklistReasonsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').BlacklistReasonsQuery, import('./api').BlacklistReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').BlacklistReasonsQuery, import('./api').BlacklistReasonsQueryVariables>(Operations.BlacklistReasons, options);
      }
export function useBlacklistReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').BlacklistReasonsQuery, import('./api').BlacklistReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').BlacklistReasonsQuery, import('./api').BlacklistReasonsQueryVariables>(Operations.BlacklistReasons, options);
        }
export type BlacklistReasonsQueryHookResult = ReturnType<typeof useBlacklistReasonsQuery>;
export type BlacklistReasonsLazyQueryHookResult = ReturnType<typeof useBlacklistReasonsLazyQuery>;
export function useBlacklistReasonQuery(baseOptions: Apollo.QueryHookOptions<import('./api').BlacklistReasonQuery, import('./api').BlacklistReasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').BlacklistReasonQuery, import('./api').BlacklistReasonQueryVariables>(Operations.BlacklistReason, options);
      }
export function useBlacklistReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').BlacklistReasonQuery, import('./api').BlacklistReasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').BlacklistReasonQuery, import('./api').BlacklistReasonQueryVariables>(Operations.BlacklistReason, options);
        }
export type BlacklistReasonQueryHookResult = ReturnType<typeof useBlacklistReasonQuery>;
export type BlacklistReasonLazyQueryHookResult = ReturnType<typeof useBlacklistReasonLazyQuery>;
export function useCheckupKindsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CheckupKindsQuery, import('./api').CheckupKindsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupKindsQuery, import('./api').CheckupKindsQueryVariables>(Operations.CheckupKinds, options);
      }
export function useCheckupKindsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupKindsQuery, import('./api').CheckupKindsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupKindsQuery, import('./api').CheckupKindsQueryVariables>(Operations.CheckupKinds, options);
        }
export type CheckupKindsQueryHookResult = ReturnType<typeof useCheckupKindsQuery>;
export type CheckupKindsLazyQueryHookResult = ReturnType<typeof useCheckupKindsLazyQuery>;
export function useCheckupKindQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CheckupKindQuery, import('./api').CheckupKindQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupKindQuery, import('./api').CheckupKindQueryVariables>(Operations.CheckupKind, options);
      }
export function useCheckupKindLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupKindQuery, import('./api').CheckupKindQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupKindQuery, import('./api').CheckupKindQueryVariables>(Operations.CheckupKind, options);
        }
export type CheckupKindQueryHookResult = ReturnType<typeof useCheckupKindQuery>;
export type CheckupKindLazyQueryHookResult = ReturnType<typeof useCheckupKindLazyQuery>;
export function useCheckupsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CheckupsQuery, import('./api').CheckupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupsQuery, import('./api').CheckupsQueryVariables>(Operations.Checkups, options);
      }
export function useCheckupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupsQuery, import('./api').CheckupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupsQuery, import('./api').CheckupsQueryVariables>(Operations.Checkups, options);
        }
export type CheckupsQueryHookResult = ReturnType<typeof useCheckupsQuery>;
export type CheckupsLazyQueryHookResult = ReturnType<typeof useCheckupsLazyQuery>;
export function useCheckupQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CheckupQuery, import('./api').CheckupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupQuery, import('./api').CheckupQueryVariables>(Operations.Checkup, options);
      }
export function useCheckupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupQuery, import('./api').CheckupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupQuery, import('./api').CheckupQueryVariables>(Operations.Checkup, options);
        }
export type CheckupQueryHookResult = ReturnType<typeof useCheckupQuery>;
export type CheckupLazyQueryHookResult = ReturnType<typeof useCheckupLazyQuery>;
export function useClientCheckupKindsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ClientCheckupKindsQuery, import('./api').ClientCheckupKindsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientCheckupKindsQuery, import('./api').ClientCheckupKindsQueryVariables>(Operations.ClientCheckupKinds, options);
      }
export function useClientCheckupKindsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientCheckupKindsQuery, import('./api').ClientCheckupKindsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientCheckupKindsQuery, import('./api').ClientCheckupKindsQueryVariables>(Operations.ClientCheckupKinds, options);
        }
export type ClientCheckupKindsQueryHookResult = ReturnType<typeof useClientCheckupKindsQuery>;
export type ClientCheckupKindsLazyQueryHookResult = ReturnType<typeof useClientCheckupKindsLazyQuery>;
export function useClientCheckupKindQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ClientCheckupKindQuery, import('./api').ClientCheckupKindQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientCheckupKindQuery, import('./api').ClientCheckupKindQueryVariables>(Operations.ClientCheckupKind, options);
      }
export function useClientCheckupKindLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientCheckupKindQuery, import('./api').ClientCheckupKindQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientCheckupKindQuery, import('./api').ClientCheckupKindQueryVariables>(Operations.ClientCheckupKind, options);
        }
export type ClientCheckupKindQueryHookResult = ReturnType<typeof useClientCheckupKindQuery>;
export type ClientCheckupKindLazyQueryHookResult = ReturnType<typeof useClientCheckupKindLazyQuery>;
export function useClientProvidersQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ClientProvidersQuery, import('./api').ClientProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientProvidersQuery, import('./api').ClientProvidersQueryVariables>(Operations.ClientProviders, options);
      }
export function useClientProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientProvidersQuery, import('./api').ClientProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientProvidersQuery, import('./api').ClientProvidersQueryVariables>(Operations.ClientProviders, options);
        }
export type ClientProvidersQueryHookResult = ReturnType<typeof useClientProvidersQuery>;
export type ClientProvidersLazyQueryHookResult = ReturnType<typeof useClientProvidersLazyQuery>;
export function useClientProviderQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ClientProviderQuery, import('./api').ClientProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientProviderQuery, import('./api').ClientProviderQueryVariables>(Operations.ClientProvider, options);
      }
export function useClientProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientProviderQuery, import('./api').ClientProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientProviderQuery, import('./api').ClientProviderQueryVariables>(Operations.ClientProvider, options);
        }
export type ClientProviderQueryHookResult = ReturnType<typeof useClientProviderQuery>;
export type ClientProviderLazyQueryHookResult = ReturnType<typeof useClientProviderLazyQuery>;
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ClientsQuery, import('./api').ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientsQuery, import('./api').ClientsQueryVariables>(Operations.Clients, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientsQuery, import('./api').ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientsQuery, import('./api').ClientsQueryVariables>(Operations.Clients, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export function useClientQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ClientQuery, import('./api').ClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientQuery, import('./api').ClientQueryVariables>(Operations.Client, options);
      }
export function useClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientQuery, import('./api').ClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientQuery, import('./api').ClientQueryVariables>(Operations.Client, options);
        }
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export function useProviderCredentialsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ProviderCredentialsQuery, import('./api').ProviderCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ProviderCredentialsQuery, import('./api').ProviderCredentialsQueryVariables>(Operations.ProviderCredentials, options);
      }
export function useProviderCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ProviderCredentialsQuery, import('./api').ProviderCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ProviderCredentialsQuery, import('./api').ProviderCredentialsQueryVariables>(Operations.ProviderCredentials, options);
        }
export type ProviderCredentialsQueryHookResult = ReturnType<typeof useProviderCredentialsQuery>;
export type ProviderCredentialsLazyQueryHookResult = ReturnType<typeof useProviderCredentialsLazyQuery>;
export function useClientProviderCredentialsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ClientProviderCredentialsQuery, import('./api').ClientProviderCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientProviderCredentialsQuery, import('./api').ClientProviderCredentialsQueryVariables>(Operations.ClientProviderCredentials, options);
      }
export function useClientProviderCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientProviderCredentialsQuery, import('./api').ClientProviderCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientProviderCredentialsQuery, import('./api').ClientProviderCredentialsQueryVariables>(Operations.ClientProviderCredentials, options);
        }
export type ClientProviderCredentialsQueryHookResult = ReturnType<typeof useClientProviderCredentialsQuery>;
export type ClientProviderCredentialsLazyQueryHookResult = ReturnType<typeof useClientProviderCredentialsLazyQuery>;
export function useCheckupKindCredentialsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CheckupKindCredentialsQuery, import('./api').CheckupKindCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupKindCredentialsQuery, import('./api').CheckupKindCredentialsQueryVariables>(Operations.CheckupKindCredentials, options);
      }
export function useCheckupKindCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupKindCredentialsQuery, import('./api').CheckupKindCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupKindCredentialsQuery, import('./api').CheckupKindCredentialsQueryVariables>(Operations.CheckupKindCredentials, options);
        }
export type CheckupKindCredentialsQueryHookResult = ReturnType<typeof useCheckupKindCredentialsQuery>;
export type CheckupKindCredentialsLazyQueryHookResult = ReturnType<typeof useCheckupKindCredentialsLazyQuery>;
export function useClientCheckupKindCredentialsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ClientCheckupKindCredentialsQuery, import('./api').ClientCheckupKindCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientCheckupKindCredentialsQuery, import('./api').ClientCheckupKindCredentialsQueryVariables>(Operations.ClientCheckupKindCredentials, options);
      }
export function useClientCheckupKindCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientCheckupKindCredentialsQuery, import('./api').ClientCheckupKindCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientCheckupKindCredentialsQuery, import('./api').ClientCheckupKindCredentialsQueryVariables>(Operations.ClientCheckupKindCredentials, options);
        }
export type ClientCheckupKindCredentialsQueryHookResult = ReturnType<typeof useClientCheckupKindCredentialsQuery>;
export type ClientCheckupKindCredentialsLazyQueryHookResult = ReturnType<typeof useClientCheckupKindCredentialsLazyQuery>;
export function useFieldTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').FieldTemplatesQuery, import('./api').FieldTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FieldTemplatesQuery, import('./api').FieldTemplatesQueryVariables>(Operations.FieldTemplates, options);
      }
export function useFieldTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FieldTemplatesQuery, import('./api').FieldTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FieldTemplatesQuery, import('./api').FieldTemplatesQueryVariables>(Operations.FieldTemplates, options);
        }
export type FieldTemplatesQueryHookResult = ReturnType<typeof useFieldTemplatesQuery>;
export type FieldTemplatesLazyQueryHookResult = ReturnType<typeof useFieldTemplatesLazyQuery>;
export function useFieldTemplateQuery(baseOptions: Apollo.QueryHookOptions<import('./api').FieldTemplateQuery, import('./api').FieldTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FieldTemplateQuery, import('./api').FieldTemplateQueryVariables>(Operations.FieldTemplate, options);
      }
export function useFieldTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FieldTemplateQuery, import('./api').FieldTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FieldTemplateQuery, import('./api').FieldTemplateQueryVariables>(Operations.FieldTemplate, options);
        }
export type FieldTemplateQueryHookResult = ReturnType<typeof useFieldTemplateQuery>;
export type FieldTemplateLazyQueryHookResult = ReturnType<typeof useFieldTemplateLazyQuery>;
export function useAdminFieldTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AdminFieldTemplatesQuery, import('./api').AdminFieldTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AdminFieldTemplatesQuery, import('./api').AdminFieldTemplatesQueryVariables>(Operations.AdminFieldTemplates, options);
      }
export function useAdminFieldTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AdminFieldTemplatesQuery, import('./api').AdminFieldTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AdminFieldTemplatesQuery, import('./api').AdminFieldTemplatesQueryVariables>(Operations.AdminFieldTemplates, options);
        }
export type AdminFieldTemplatesQueryHookResult = ReturnType<typeof useAdminFieldTemplatesQuery>;
export type AdminFieldTemplatesLazyQueryHookResult = ReturnType<typeof useAdminFieldTemplatesLazyQuery>;
export function useAdminFieldTemplateQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AdminFieldTemplateQuery, import('./api').AdminFieldTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AdminFieldTemplateQuery, import('./api').AdminFieldTemplateQueryVariables>(Operations.AdminFieldTemplate, options);
      }
export function useAdminFieldTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AdminFieldTemplateQuery, import('./api').AdminFieldTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AdminFieldTemplateQuery, import('./api').AdminFieldTemplateQueryVariables>(Operations.AdminFieldTemplate, options);
        }
export type AdminFieldTemplateQueryHookResult = ReturnType<typeof useAdminFieldTemplateQuery>;
export type AdminFieldTemplateLazyQueryHookResult = ReturnType<typeof useAdminFieldTemplateLazyQuery>;
export function useFormTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').FormTemplatesQuery, import('./api').FormTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FormTemplatesQuery, import('./api').FormTemplatesQueryVariables>(Operations.FormTemplates, options);
      }
export function useFormTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FormTemplatesQuery, import('./api').FormTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FormTemplatesQuery, import('./api').FormTemplatesQueryVariables>(Operations.FormTemplates, options);
        }
export type FormTemplatesQueryHookResult = ReturnType<typeof useFormTemplatesQuery>;
export type FormTemplatesLazyQueryHookResult = ReturnType<typeof useFormTemplatesLazyQuery>;
export function useClientFormTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ClientFormTemplatesQuery, import('./api').ClientFormTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientFormTemplatesQuery, import('./api').ClientFormTemplatesQueryVariables>(Operations.ClientFormTemplates, options);
      }
export function useClientFormTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientFormTemplatesQuery, import('./api').ClientFormTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientFormTemplatesQuery, import('./api').ClientFormTemplatesQueryVariables>(Operations.ClientFormTemplates, options);
        }
export type ClientFormTemplatesQueryHookResult = ReturnType<typeof useClientFormTemplatesQuery>;
export type ClientFormTemplatesLazyQueryHookResult = ReturnType<typeof useClientFormTemplatesLazyQuery>;
export function useInstantCheckupsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').InstantCheckupsQuery, import('./api').InstantCheckupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').InstantCheckupsQuery, import('./api').InstantCheckupsQueryVariables>(Operations.InstantCheckups, options);
      }
export function useInstantCheckupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').InstantCheckupsQuery, import('./api').InstantCheckupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').InstantCheckupsQuery, import('./api').InstantCheckupsQueryVariables>(Operations.InstantCheckups, options);
        }
export type InstantCheckupsQueryHookResult = ReturnType<typeof useInstantCheckupsQuery>;
export type InstantCheckupsLazyQueryHookResult = ReturnType<typeof useInstantCheckupsLazyQuery>;
export function useInstantCheckupQuery(baseOptions: Apollo.QueryHookOptions<import('./api').InstantCheckupQuery, import('./api').InstantCheckupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').InstantCheckupQuery, import('./api').InstantCheckupQueryVariables>(Operations.InstantCheckup, options);
      }
export function useInstantCheckupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').InstantCheckupQuery, import('./api').InstantCheckupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').InstantCheckupQuery, import('./api').InstantCheckupQueryVariables>(Operations.InstantCheckup, options);
        }
export type InstantCheckupQueryHookResult = ReturnType<typeof useInstantCheckupQuery>;
export type InstantCheckupLazyQueryHookResult = ReturnType<typeof useInstantCheckupLazyQuery>;
export function useProvidersQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ProvidersQuery, import('./api').ProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ProvidersQuery, import('./api').ProvidersQueryVariables>(Operations.Providers, options);
      }
export function useProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ProvidersQuery, import('./api').ProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ProvidersQuery, import('./api').ProvidersQueryVariables>(Operations.Providers, options);
        }
export type ProvidersQueryHookResult = ReturnType<typeof useProvidersQuery>;
export type ProvidersLazyQueryHookResult = ReturnType<typeof useProvidersLazyQuery>;
export function useProviderQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ProviderQuery, import('./api').ProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ProviderQuery, import('./api').ProviderQueryVariables>(Operations.Provider, options);
      }
export function useProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ProviderQuery, import('./api').ProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ProviderQuery, import('./api').ProviderQueryVariables>(Operations.Provider, options);
        }
export type ProviderQueryHookResult = ReturnType<typeof useProviderQuery>;
export type ProviderLazyQueryHookResult = ReturnType<typeof useProviderLazyQuery>;
export function useRegionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').RegionsQuery, import('./api').RegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RegionsQuery, import('./api').RegionsQueryVariables>(Operations.Regions, options);
      }
export function useRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RegionsQuery, import('./api').RegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RegionsQuery, import('./api').RegionsQueryVariables>(Operations.Regions, options);
        }
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>;
export function useRegionQuery(baseOptions: Apollo.QueryHookOptions<import('./api').RegionQuery, import('./api').RegionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RegionQuery, import('./api').RegionQueryVariables>(Operations.Region, options);
      }
export function useRegionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RegionQuery, import('./api').RegionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RegionQuery, import('./api').RegionQueryVariables>(Operations.Region, options);
        }
export type RegionQueryHookResult = ReturnType<typeof useRegionQuery>;
export type RegionLazyQueryHookResult = ReturnType<typeof useRegionLazyQuery>;
export function useAdminStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AdminStatsQuery, import('./api').AdminStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AdminStatsQuery, import('./api').AdminStatsQueryVariables>(Operations.AdminStats, options);
      }
export function useAdminStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AdminStatsQuery, import('./api').AdminStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AdminStatsQuery, import('./api').AdminStatsQueryVariables>(Operations.AdminStats, options);
        }
export type AdminStatsQueryHookResult = ReturnType<typeof useAdminStatsQuery>;
export type AdminStatsLazyQueryHookResult = ReturnType<typeof useAdminStatsLazyQuery>;
export function useStopWordsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').StopWordsQuery, import('./api').StopWordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').StopWordsQuery, import('./api').StopWordsQueryVariables>(Operations.StopWords, options);
      }
export function useStopWordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').StopWordsQuery, import('./api').StopWordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').StopWordsQuery, import('./api').StopWordsQueryVariables>(Operations.StopWords, options);
        }
export type StopWordsQueryHookResult = ReturnType<typeof useStopWordsQuery>;
export type StopWordsLazyQueryHookResult = ReturnType<typeof useStopWordsLazyQuery>;
export function useStopWordQuery(baseOptions: Apollo.QueryHookOptions<import('./api').StopWordQuery, import('./api').StopWordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').StopWordQuery, import('./api').StopWordQueryVariables>(Operations.StopWord, options);
      }
export function useStopWordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').StopWordQuery, import('./api').StopWordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').StopWordQuery, import('./api').StopWordQueryVariables>(Operations.StopWord, options);
        }
export type StopWordQueryHookResult = ReturnType<typeof useStopWordQuery>;
export type StopWordLazyQueryHookResult = ReturnType<typeof useStopWordLazyQuery>;
export function useAiDictAllQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AiDictAllQuery, import('./api').AiDictAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiDictAllQuery, import('./api').AiDictAllQueryVariables>(Operations.AIDictAll, options);
      }
export function useAiDictAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiDictAllQuery, import('./api').AiDictAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiDictAllQuery, import('./api').AiDictAllQueryVariables>(Operations.AIDictAll, options);
        }
export type AiDictAllQueryHookResult = ReturnType<typeof useAiDictAllQuery>;
export type AiDictAllLazyQueryHookResult = ReturnType<typeof useAiDictAllLazyQuery>;
export function useAiDictForTaskQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AiDictForTaskQuery, import('./api').AiDictForTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiDictForTaskQuery, import('./api').AiDictForTaskQueryVariables>(Operations.AIDictForTask, options);
      }
export function useAiDictForTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiDictForTaskQuery, import('./api').AiDictForTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiDictForTaskQuery, import('./api').AiDictForTaskQueryVariables>(Operations.AIDictForTask, options);
        }
export type AiDictForTaskQueryHookResult = ReturnType<typeof useAiDictForTaskQuery>;
export type AiDictForTaskLazyQueryHookResult = ReturnType<typeof useAiDictForTaskLazyQuery>;
export function useAiDictForConfigQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AiDictForConfigQuery, import('./api').AiDictForConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiDictForConfigQuery, import('./api').AiDictForConfigQueryVariables>(Operations.AIDictForConfig, options);
      }
export function useAiDictForConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiDictForConfigQuery, import('./api').AiDictForConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiDictForConfigQuery, import('./api').AiDictForConfigQueryVariables>(Operations.AIDictForConfig, options);
        }
export type AiDictForConfigQueryHookResult = ReturnType<typeof useAiDictForConfigQuery>;
export type AiDictForConfigLazyQueryHookResult = ReturnType<typeof useAiDictForConfigLazyQuery>;
export function useAiTaskConfigsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AiTaskConfigsQuery, import('./api').AiTaskConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiTaskConfigsQuery, import('./api').AiTaskConfigsQueryVariables>(Operations.AITaskConfigs, options);
      }
export function useAiTaskConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiTaskConfigsQuery, import('./api').AiTaskConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiTaskConfigsQuery, import('./api').AiTaskConfigsQueryVariables>(Operations.AITaskConfigs, options);
        }
export type AiTaskConfigsQueryHookResult = ReturnType<typeof useAiTaskConfigsQuery>;
export type AiTaskConfigsLazyQueryHookResult = ReturnType<typeof useAiTaskConfigsLazyQuery>;
export function useAiTaskConfigQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AiTaskConfigQuery, import('./api').AiTaskConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiTaskConfigQuery, import('./api').AiTaskConfigQueryVariables>(Operations.AITaskConfig, options);
      }
export function useAiTaskConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiTaskConfigQuery, import('./api').AiTaskConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiTaskConfigQuery, import('./api').AiTaskConfigQueryVariables>(Operations.AITaskConfig, options);
        }
export type AiTaskConfigQueryHookResult = ReturnType<typeof useAiTaskConfigQuery>;
export type AiTaskConfigLazyQueryHookResult = ReturnType<typeof useAiTaskConfigLazyQuery>;
export function useAiTaskResultsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AiTaskResultsQuery, import('./api').AiTaskResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiTaskResultsQuery, import('./api').AiTaskResultsQueryVariables>(Operations.AITaskResults, options);
      }
export function useAiTaskResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiTaskResultsQuery, import('./api').AiTaskResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiTaskResultsQuery, import('./api').AiTaskResultsQueryVariables>(Operations.AITaskResults, options);
        }
export type AiTaskResultsQueryHookResult = ReturnType<typeof useAiTaskResultsQuery>;
export type AiTaskResultsLazyQueryHookResult = ReturnType<typeof useAiTaskResultsLazyQuery>;
export function useAiTaskResultQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AiTaskResultQuery, import('./api').AiTaskResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiTaskResultQuery, import('./api').AiTaskResultQueryVariables>(Operations.AITaskResult, options);
      }
export function useAiTaskResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiTaskResultQuery, import('./api').AiTaskResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiTaskResultQuery, import('./api').AiTaskResultQueryVariables>(Operations.AITaskResult, options);
        }
export type AiTaskResultQueryHookResult = ReturnType<typeof useAiTaskResultQuery>;
export type AiTaskResultLazyQueryHookResult = ReturnType<typeof useAiTaskResultLazyQuery>;
export function useAiTasksQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AiTasksQuery, import('./api').AiTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiTasksQuery, import('./api').AiTasksQueryVariables>(Operations.AITasks, options);
      }
export function useAiTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiTasksQuery, import('./api').AiTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiTasksQuery, import('./api').AiTasksQueryVariables>(Operations.AITasks, options);
        }
export type AiTasksQueryHookResult = ReturnType<typeof useAiTasksQuery>;
export type AiTasksLazyQueryHookResult = ReturnType<typeof useAiTasksLazyQuery>;
export function useAiTaskQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AiTaskQuery, import('./api').AiTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiTaskQuery, import('./api').AiTaskQueryVariables>(Operations.AITask, options);
      }
export function useAiTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiTaskQuery, import('./api').AiTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiTaskQuery, import('./api').AiTaskQueryVariables>(Operations.AITask, options);
        }
export type AiTaskQueryHookResult = ReturnType<typeof useAiTaskQuery>;
export type AiTaskLazyQueryHookResult = ReturnType<typeof useAiTaskLazyQuery>;
export function useAiSummaryConfigsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AiSummaryConfigsQuery, import('./api').AiSummaryConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiSummaryConfigsQuery, import('./api').AiSummaryConfigsQueryVariables>(Operations.AiSummaryConfigs, options);
      }
export function useAiSummaryConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiSummaryConfigsQuery, import('./api').AiSummaryConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiSummaryConfigsQuery, import('./api').AiSummaryConfigsQueryVariables>(Operations.AiSummaryConfigs, options);
        }
export type AiSummaryConfigsQueryHookResult = ReturnType<typeof useAiSummaryConfigsQuery>;
export type AiSummaryConfigsLazyQueryHookResult = ReturnType<typeof useAiSummaryConfigsLazyQuery>;
export function useAiSummaryConfigQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AiSummaryConfigQuery, import('./api').AiSummaryConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiSummaryConfigQuery, import('./api').AiSummaryConfigQueryVariables>(Operations.AiSummaryConfig, options);
      }
export function useAiSummaryConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiSummaryConfigQuery, import('./api').AiSummaryConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiSummaryConfigQuery, import('./api').AiSummaryConfigQueryVariables>(Operations.AiSummaryConfig, options);
        }
export type AiSummaryConfigQueryHookResult = ReturnType<typeof useAiSummaryConfigQuery>;
export type AiSummaryConfigLazyQueryHookResult = ReturnType<typeof useAiSummaryConfigLazyQuery>;
export function useAiSummariesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AiSummariesQuery, import('./api').AiSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiSummariesQuery, import('./api').AiSummariesQueryVariables>(Operations.AiSummaries, options);
      }
export function useAiSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiSummariesQuery, import('./api').AiSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiSummariesQuery, import('./api').AiSummariesQueryVariables>(Operations.AiSummaries, options);
        }
export type AiSummariesQueryHookResult = ReturnType<typeof useAiSummariesQuery>;
export type AiSummariesLazyQueryHookResult = ReturnType<typeof useAiSummariesLazyQuery>;
export function useAiSummaryQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AiSummaryQuery, import('./api').AiSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiSummaryQuery, import('./api').AiSummaryQueryVariables>(Operations.AiSummary, options);
      }
export function useAiSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiSummaryQuery, import('./api').AiSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiSummaryQuery, import('./api').AiSummaryQueryVariables>(Operations.AiSummary, options);
        }
export type AiSummaryQueryHookResult = ReturnType<typeof useAiSummaryQuery>;
export type AiSummaryLazyQueryHookResult = ReturnType<typeof useAiSummaryLazyQuery>;
export function useAiSummaryRevisionsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AiSummaryRevisionsQuery, import('./api').AiSummaryRevisionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AiSummaryRevisionsQuery, import('./api').AiSummaryRevisionsQueryVariables>(Operations.AiSummaryRevisions, options);
      }
export function useAiSummaryRevisionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AiSummaryRevisionsQuery, import('./api').AiSummaryRevisionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AiSummaryRevisionsQuery, import('./api').AiSummaryRevisionsQueryVariables>(Operations.AiSummaryRevisions, options);
        }
export type AiSummaryRevisionsQueryHookResult = ReturnType<typeof useAiSummaryRevisionsQuery>;
export type AiSummaryRevisionsLazyQueryHookResult = ReturnType<typeof useAiSummaryRevisionsLazyQuery>;
export function useAuditsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AuditsQuery, import('./api').AuditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AuditsQuery, import('./api').AuditsQueryVariables>(Operations.Audits, options);
      }
export function useAuditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AuditsQuery, import('./api').AuditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AuditsQuery, import('./api').AuditsQueryVariables>(Operations.Audits, options);
        }
export type AuditsQueryHookResult = ReturnType<typeof useAuditsQuery>;
export type AuditsLazyQueryHookResult = ReturnType<typeof useAuditsLazyQuery>;
export function useAuditRecordsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AuditRecordsQuery, import('./api').AuditRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AuditRecordsQuery, import('./api').AuditRecordsQueryVariables>(Operations.AuditRecords, options);
      }
export function useAuditRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AuditRecordsQuery, import('./api').AuditRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AuditRecordsQuery, import('./api').AuditRecordsQueryVariables>(Operations.AuditRecords, options);
        }
export type AuditRecordsQueryHookResult = ReturnType<typeof useAuditRecordsQuery>;
export type AuditRecordsLazyQueryHookResult = ReturnType<typeof useAuditRecordsLazyQuery>;
export function useAuditQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AuditQuery, import('./api').AuditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AuditQuery, import('./api').AuditQueryVariables>(Operations.Audit, options);
      }
export function useAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AuditQuery, import('./api').AuditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AuditQuery, import('./api').AuditQueryVariables>(Operations.Audit, options);
        }
export type AuditQueryHookResult = ReturnType<typeof useAuditQuery>;
export type AuditLazyQueryHookResult = ReturnType<typeof useAuditLazyQuery>;
export function useBalanceQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').BalanceQuery, import('./api').BalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').BalanceQuery, import('./api').BalanceQueryVariables>(Operations.Balance, options);
      }
export function useBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').BalanceQuery, import('./api').BalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').BalanceQuery, import('./api').BalanceQueryVariables>(Operations.Balance, options);
        }
export type BalanceQueryHookResult = ReturnType<typeof useBalanceQuery>;
export type BalanceLazyQueryHookResult = ReturnType<typeof useBalanceLazyQuery>;
export function useBlacklistedDeviceInfosQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').BlacklistedDeviceInfosQuery, import('./api').BlacklistedDeviceInfosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').BlacklistedDeviceInfosQuery, import('./api').BlacklistedDeviceInfosQueryVariables>(Operations.BlacklistedDeviceInfos, options);
      }
export function useBlacklistedDeviceInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').BlacklistedDeviceInfosQuery, import('./api').BlacklistedDeviceInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').BlacklistedDeviceInfosQuery, import('./api').BlacklistedDeviceInfosQueryVariables>(Operations.BlacklistedDeviceInfos, options);
        }
export type BlacklistedDeviceInfosQueryHookResult = ReturnType<typeof useBlacklistedDeviceInfosQuery>;
export type BlacklistedDeviceInfosLazyQueryHookResult = ReturnType<typeof useBlacklistedDeviceInfosLazyQuery>;
export function useBlacklistedDeviceInfoQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').BlacklistedDeviceInfoQuery, import('./api').BlacklistedDeviceInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').BlacklistedDeviceInfoQuery, import('./api').BlacklistedDeviceInfoQueryVariables>(Operations.BlacklistedDeviceInfo, options);
      }
export function useBlacklistedDeviceInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').BlacklistedDeviceInfoQuery, import('./api').BlacklistedDeviceInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').BlacklistedDeviceInfoQuery, import('./api').BlacklistedDeviceInfoQueryVariables>(Operations.BlacklistedDeviceInfo, options);
        }
export type BlacklistedDeviceInfoQueryHookResult = ReturnType<typeof useBlacklistedDeviceInfoQuery>;
export type BlacklistedDeviceInfoLazyQueryHookResult = ReturnType<typeof useBlacklistedDeviceInfoLazyQuery>;
export function useAvailableFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AvailableFeaturesQuery, import('./api').AvailableFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AvailableFeaturesQuery, import('./api').AvailableFeaturesQueryVariables>(Operations.AvailableFeatures, options);
      }
export function useAvailableFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AvailableFeaturesQuery, import('./api').AvailableFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AvailableFeaturesQuery, import('./api').AvailableFeaturesQueryVariables>(Operations.AvailableFeatures, options);
        }
export type AvailableFeaturesQueryHookResult = ReturnType<typeof useAvailableFeaturesQuery>;
export type AvailableFeaturesLazyQueryHookResult = ReturnType<typeof useAvailableFeaturesLazyQuery>;
export function useCaseConfigQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseConfigQuery, import('./api').CaseConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseConfigQuery, import('./api').CaseConfigQueryVariables>(Operations.CaseConfig, options);
      }
export function useCaseConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseConfigQuery, import('./api').CaseConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseConfigQuery, import('./api').CaseConfigQueryVariables>(Operations.CaseConfig, options);
        }
export type CaseConfigQueryHookResult = ReturnType<typeof useCaseConfigQuery>;
export type CaseConfigLazyQueryHookResult = ReturnType<typeof useCaseConfigLazyQuery>;
export function useCdsCableAccountStatusesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCableAccountStatusesQuery, import('./api').CdsCableAccountStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCableAccountStatusesQuery, import('./api').CdsCableAccountStatusesQueryVariables>(Operations.CdsCableAccountStatuses, options);
      }
export function useCdsCableAccountStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCableAccountStatusesQuery, import('./api').CdsCableAccountStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCableAccountStatusesQuery, import('./api').CdsCableAccountStatusesQueryVariables>(Operations.CdsCableAccountStatuses, options);
        }
export type CdsCableAccountStatusesQueryHookResult = ReturnType<typeof useCdsCableAccountStatusesQuery>;
export type CdsCableAccountStatusesLazyQueryHookResult = ReturnType<typeof useCdsCableAccountStatusesLazyQuery>;
export function useCdsCableAddressesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCableAddressesQuery, import('./api').CdsCableAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCableAddressesQuery, import('./api').CdsCableAddressesQueryVariables>(Operations.CdsCableAddresses, options);
      }
export function useCdsCableAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCableAddressesQuery, import('./api').CdsCableAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCableAddressesQuery, import('./api').CdsCableAddressesQueryVariables>(Operations.CdsCableAddresses, options);
        }
export type CdsCableAddressesQueryHookResult = ReturnType<typeof useCdsCableAddressesQuery>;
export type CdsCableAddressesLazyQueryHookResult = ReturnType<typeof useCdsCableAddressesLazyQuery>;
export function useCdsCableRefreshTasksQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCableRefreshTasksQuery, import('./api').CdsCableRefreshTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCableRefreshTasksQuery, import('./api').CdsCableRefreshTasksQueryVariables>(Operations.CdsCableRefreshTasks, options);
      }
export function useCdsCableRefreshTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCableRefreshTasksQuery, import('./api').CdsCableRefreshTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCableRefreshTasksQuery, import('./api').CdsCableRefreshTasksQueryVariables>(Operations.CdsCableRefreshTasks, options);
        }
export type CdsCableRefreshTasksQueryHookResult = ReturnType<typeof useCdsCableRefreshTasksQuery>;
export type CdsCableRefreshTasksLazyQueryHookResult = ReturnType<typeof useCdsCableRefreshTasksLazyQuery>;
export function useCdsCableCsvExportsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCableCsvExportsQuery, import('./api').CdsCableCsvExportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCableCsvExportsQuery, import('./api').CdsCableCsvExportsQueryVariables>(Operations.CdsCableCsvExports, options);
      }
export function useCdsCableCsvExportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCableCsvExportsQuery, import('./api').CdsCableCsvExportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCableCsvExportsQuery, import('./api').CdsCableCsvExportsQueryVariables>(Operations.CdsCableCsvExports, options);
        }
export type CdsCableCsvExportsQueryHookResult = ReturnType<typeof useCdsCableCsvExportsQuery>;
export type CdsCableCsvExportsLazyQueryHookResult = ReturnType<typeof useCdsCableCsvExportsLazyQuery>;
export function useCdsCableCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCableCompaniesQuery, import('./api').CdsCableCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCableCompaniesQuery, import('./api').CdsCableCompaniesQueryVariables>(Operations.CdsCableCompanies, options);
      }
export function useCdsCableCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCableCompaniesQuery, import('./api').CdsCableCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCableCompaniesQuery, import('./api').CdsCableCompaniesQueryVariables>(Operations.CdsCableCompanies, options);
        }
export type CdsCableCompaniesQueryHookResult = ReturnType<typeof useCdsCableCompaniesQuery>;
export type CdsCableCompaniesLazyQueryHookResult = ReturnType<typeof useCdsCableCompaniesLazyQuery>;
export function useCdsCableCompanyQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCableCompanyQuery, import('./api').CdsCableCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCableCompanyQuery, import('./api').CdsCableCompanyQueryVariables>(Operations.CdsCableCompany, options);
      }
export function useCdsCableCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCableCompanyQuery, import('./api').CdsCableCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCableCompanyQuery, import('./api').CdsCableCompanyQueryVariables>(Operations.CdsCableCompany, options);
        }
export type CdsCableCompanyQueryHookResult = ReturnType<typeof useCdsCableCompanyQuery>;
export type CdsCableCompanyLazyQueryHookResult = ReturnType<typeof useCdsCableCompanyLazyQuery>;
export function useCdsDictionaryQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsDictionaryQuery, import('./api').CdsDictionaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsDictionaryQuery, import('./api').CdsDictionaryQueryVariables>(Operations.CdsDictionary, options);
      }
export function useCdsDictionaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsDictionaryQuery, import('./api').CdsDictionaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsDictionaryQuery, import('./api').CdsDictionaryQueryVariables>(Operations.CdsDictionary, options);
        }
export type CdsDictionaryQueryHookResult = ReturnType<typeof useCdsDictionaryQuery>;
export type CdsDictionaryLazyQueryHookResult = ReturnType<typeof useCdsDictionaryLazyQuery>;
export function useCdsEntityTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsEntityTemplatesQuery, import('./api').CdsEntityTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsEntityTemplatesQuery, import('./api').CdsEntityTemplatesQueryVariables>(Operations.CdsEntityTemplates, options);
      }
export function useCdsEntityTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsEntityTemplatesQuery, import('./api').CdsEntityTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsEntityTemplatesQuery, import('./api').CdsEntityTemplatesQueryVariables>(Operations.CdsEntityTemplates, options);
        }
export type CdsEntityTemplatesQueryHookResult = ReturnType<typeof useCdsEntityTemplatesQuery>;
export type CdsEntityTemplatesLazyQueryHookResult = ReturnType<typeof useCdsEntityTemplatesLazyQuery>;
export function useCdsEntityTemplateQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CdsEntityTemplateQuery, import('./api').CdsEntityTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsEntityTemplateQuery, import('./api').CdsEntityTemplateQueryVariables>(Operations.CdsEntityTemplate, options);
      }
export function useCdsEntityTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsEntityTemplateQuery, import('./api').CdsEntityTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsEntityTemplateQuery, import('./api').CdsEntityTemplateQueryVariables>(Operations.CdsEntityTemplate, options);
        }
export type CdsEntityTemplateQueryHookResult = ReturnType<typeof useCdsEntityTemplateQuery>;
export type CdsEntityTemplateLazyQueryHookResult = ReturnType<typeof useCdsEntityTemplateLazyQuery>;
export function useCdsFieldMappingsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsFieldMappingsQuery, import('./api').CdsFieldMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsFieldMappingsQuery, import('./api').CdsFieldMappingsQueryVariables>(Operations.CdsFieldMappings, options);
      }
export function useCdsFieldMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsFieldMappingsQuery, import('./api').CdsFieldMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsFieldMappingsQuery, import('./api').CdsFieldMappingsQueryVariables>(Operations.CdsFieldMappings, options);
        }
export type CdsFieldMappingsQueryHookResult = ReturnType<typeof useCdsFieldMappingsQuery>;
export type CdsFieldMappingsLazyQueryHookResult = ReturnType<typeof useCdsFieldMappingsLazyQuery>;
export function useCdsFieldMappingQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CdsFieldMappingQuery, import('./api').CdsFieldMappingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsFieldMappingQuery, import('./api').CdsFieldMappingQueryVariables>(Operations.CdsFieldMapping, options);
      }
export function useCdsFieldMappingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsFieldMappingQuery, import('./api').CdsFieldMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsFieldMappingQuery, import('./api').CdsFieldMappingQueryVariables>(Operations.CdsFieldMapping, options);
        }
export type CdsFieldMappingQueryHookResult = ReturnType<typeof useCdsFieldMappingQuery>;
export type CdsFieldMappingLazyQueryHookResult = ReturnType<typeof useCdsFieldMappingLazyQuery>;
export function useCdsFormConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsFormConfigurationsQuery, import('./api').CdsFormConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsFormConfigurationsQuery, import('./api').CdsFormConfigurationsQueryVariables>(Operations.CdsFormConfigurations, options);
      }
export function useCdsFormConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsFormConfigurationsQuery, import('./api').CdsFormConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsFormConfigurationsQuery, import('./api').CdsFormConfigurationsQueryVariables>(Operations.CdsFormConfigurations, options);
        }
export type CdsFormConfigurationsQueryHookResult = ReturnType<typeof useCdsFormConfigurationsQuery>;
export type CdsFormConfigurationsLazyQueryHookResult = ReturnType<typeof useCdsFormConfigurationsLazyQuery>;
export function useCdsFormConfigurationQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CdsFormConfigurationQuery, import('./api').CdsFormConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsFormConfigurationQuery, import('./api').CdsFormConfigurationQueryVariables>(Operations.CdsFormConfiguration, options);
      }
export function useCdsFormConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsFormConfigurationQuery, import('./api').CdsFormConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsFormConfigurationQuery, import('./api').CdsFormConfigurationQueryVariables>(Operations.CdsFormConfiguration, options);
        }
export type CdsFormConfigurationQueryHookResult = ReturnType<typeof useCdsFormConfigurationQuery>;
export type CdsFormConfigurationLazyQueryHookResult = ReturnType<typeof useCdsFormConfigurationLazyQuery>;
export function useCdsFormMappingsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsFormMappingsQuery, import('./api').CdsFormMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsFormMappingsQuery, import('./api').CdsFormMappingsQueryVariables>(Operations.CdsFormMappings, options);
      }
export function useCdsFormMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsFormMappingsQuery, import('./api').CdsFormMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsFormMappingsQuery, import('./api').CdsFormMappingsQueryVariables>(Operations.CdsFormMappings, options);
        }
export type CdsFormMappingsQueryHookResult = ReturnType<typeof useCdsFormMappingsQuery>;
export type CdsFormMappingsLazyQueryHookResult = ReturnType<typeof useCdsFormMappingsLazyQuery>;
export function useCdsFormMappingQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CdsFormMappingQuery, import('./api').CdsFormMappingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsFormMappingQuery, import('./api').CdsFormMappingQueryVariables>(Operations.CdsFormMapping, options);
      }
export function useCdsFormMappingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsFormMappingQuery, import('./api').CdsFormMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsFormMappingQuery, import('./api').CdsFormMappingQueryVariables>(Operations.CdsFormMapping, options);
        }
export type CdsFormMappingQueryHookResult = ReturnType<typeof useCdsFormMappingQuery>;
export type CdsFormMappingLazyQueryHookResult = ReturnType<typeof useCdsFormMappingLazyQuery>;
export function useCdsCableIdvResultsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCableIdvResultsQuery, import('./api').CdsCableIdvResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCableIdvResultsQuery, import('./api').CdsCableIdvResultsQueryVariables>(Operations.CdsCableIdvResults, options);
      }
export function useCdsCableIdvResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCableIdvResultsQuery, import('./api').CdsCableIdvResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCableIdvResultsQuery, import('./api').CdsCableIdvResultsQueryVariables>(Operations.CdsCableIdvResults, options);
        }
export type CdsCableIdvResultsQueryHookResult = ReturnType<typeof useCdsCableIdvResultsQuery>;
export type CdsCableIdvResultsLazyQueryHookResult = ReturnType<typeof useCdsCableIdvResultsLazyQuery>;
export function useCdsCableOnboardingFlowsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCableOnboardingFlowsQuery, import('./api').CdsCableOnboardingFlowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCableOnboardingFlowsQuery, import('./api').CdsCableOnboardingFlowsQueryVariables>(Operations.CdsCableOnboardingFlows, options);
      }
export function useCdsCableOnboardingFlowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCableOnboardingFlowsQuery, import('./api').CdsCableOnboardingFlowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCableOnboardingFlowsQuery, import('./api').CdsCableOnboardingFlowsQueryVariables>(Operations.CdsCableOnboardingFlows, options);
        }
export type CdsCableOnboardingFlowsQueryHookResult = ReturnType<typeof useCdsCableOnboardingFlowsQuery>;
export type CdsCableOnboardingFlowsLazyQueryHookResult = ReturnType<typeof useCdsCableOnboardingFlowsLazyQuery>;
export function useCdsCablePeoplesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCablePeoplesQuery, import('./api').CdsCablePeoplesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCablePeoplesQuery, import('./api').CdsCablePeoplesQueryVariables>(Operations.CdsCablePeoples, options);
      }
export function useCdsCablePeoplesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCablePeoplesQuery, import('./api').CdsCablePeoplesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCablePeoplesQuery, import('./api').CdsCablePeoplesQueryVariables>(Operations.CdsCablePeoples, options);
        }
export type CdsCablePeoplesQueryHookResult = ReturnType<typeof useCdsCablePeoplesQuery>;
export type CdsCablePeoplesLazyQueryHookResult = ReturnType<typeof useCdsCablePeoplesLazyQuery>;
export function useCdsCablePeopleQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCablePeopleQuery, import('./api').CdsCablePeopleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCablePeopleQuery, import('./api').CdsCablePeopleQueryVariables>(Operations.CdsCablePeople, options);
      }
export function useCdsCablePeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCablePeopleQuery, import('./api').CdsCablePeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCablePeopleQuery, import('./api').CdsCablePeopleQueryVariables>(Operations.CdsCablePeople, options);
        }
export type CdsCablePeopleQueryHookResult = ReturnType<typeof useCdsCablePeopleQuery>;
export type CdsCablePeopleLazyQueryHookResult = ReturnType<typeof useCdsCablePeopleLazyQuery>;
export function useCdsCablePeriodicReviewsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCablePeriodicReviewsQuery, import('./api').CdsCablePeriodicReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCablePeriodicReviewsQuery, import('./api').CdsCablePeriodicReviewsQueryVariables>(Operations.CdsCablePeriodicReviews, options);
      }
export function useCdsCablePeriodicReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCablePeriodicReviewsQuery, import('./api').CdsCablePeriodicReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCablePeriodicReviewsQuery, import('./api').CdsCablePeriodicReviewsQueryVariables>(Operations.CdsCablePeriodicReviews, options);
        }
export type CdsCablePeriodicReviewsQueryHookResult = ReturnType<typeof useCdsCablePeriodicReviewsQuery>;
export type CdsCablePeriodicReviewsLazyQueryHookResult = ReturnType<typeof useCdsCablePeriodicReviewsLazyQuery>;
export function useCdsCableRiskAssessmentsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCableRiskAssessmentsQuery, import('./api').CdsCableRiskAssessmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCableRiskAssessmentsQuery, import('./api').CdsCableRiskAssessmentsQueryVariables>(Operations.CdsCableRiskAssessments, options);
      }
export function useCdsCableRiskAssessmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCableRiskAssessmentsQuery, import('./api').CdsCableRiskAssessmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCableRiskAssessmentsQuery, import('./api').CdsCableRiskAssessmentsQueryVariables>(Operations.CdsCableRiskAssessments, options);
        }
export type CdsCableRiskAssessmentsQueryHookResult = ReturnType<typeof useCdsCableRiskAssessmentsQuery>;
export type CdsCableRiskAssessmentsLazyQueryHookResult = ReturnType<typeof useCdsCableRiskAssessmentsLazyQuery>;
export function useCdsCableScreeningChecksQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CdsCableScreeningChecksQuery, import('./api').CdsCableScreeningChecksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CdsCableScreeningChecksQuery, import('./api').CdsCableScreeningChecksQueryVariables>(Operations.CdsCableScreeningChecks, options);
      }
export function useCdsCableScreeningChecksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CdsCableScreeningChecksQuery, import('./api').CdsCableScreeningChecksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CdsCableScreeningChecksQuery, import('./api').CdsCableScreeningChecksQueryVariables>(Operations.CdsCableScreeningChecks, options);
        }
export type CdsCableScreeningChecksQueryHookResult = ReturnType<typeof useCdsCableScreeningChecksQuery>;
export type CdsCableScreeningChecksLazyQueryHookResult = ReturnType<typeof useCdsCableScreeningChecksLazyQuery>;
export function useAuditConfigsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AuditConfigsQuery, import('./api').AuditConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AuditConfigsQuery, import('./api').AuditConfigsQueryVariables>(Operations.AuditConfigs, options);
      }
export function useAuditConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AuditConfigsQuery, import('./api').AuditConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AuditConfigsQuery, import('./api').AuditConfigsQueryVariables>(Operations.AuditConfigs, options);
        }
export type AuditConfigsQueryHookResult = ReturnType<typeof useAuditConfigsQuery>;
export type AuditConfigsLazyQueryHookResult = ReturnType<typeof useAuditConfigsLazyQuery>;
export function useAuditConfigQuery(baseOptions: Apollo.QueryHookOptions<import('./api').AuditConfigQuery, import('./api').AuditConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AuditConfigQuery, import('./api').AuditConfigQueryVariables>(Operations.AuditConfig, options);
      }
export function useAuditConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AuditConfigQuery, import('./api').AuditConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AuditConfigQuery, import('./api').AuditConfigQueryVariables>(Operations.AuditConfig, options);
        }
export type AuditConfigQueryHookResult = ReturnType<typeof useAuditConfigQuery>;
export type AuditConfigLazyQueryHookResult = ReturnType<typeof useAuditConfigLazyQuery>;
export function useCaSearchesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaSearchesQuery, import('./api').CaSearchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaSearchesQuery, import('./api').CaSearchesQueryVariables>(Operations.CaSearches, options);
      }
export function useCaSearchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaSearchesQuery, import('./api').CaSearchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaSearchesQuery, import('./api').CaSearchesQueryVariables>(Operations.CaSearches, options);
        }
export type CaSearchesQueryHookResult = ReturnType<typeof useCaSearchesQuery>;
export type CaSearchesLazyQueryHookResult = ReturnType<typeof useCaSearchesLazyQuery>;
export function useCheckupAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CheckupAttachmentsQuery, import('./api').CheckupAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupAttachmentsQuery, import('./api').CheckupAttachmentsQueryVariables>(Operations.CheckupAttachments, options);
      }
export function useCheckupAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupAttachmentsQuery, import('./api').CheckupAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupAttachmentsQuery, import('./api').CheckupAttachmentsQueryVariables>(Operations.CheckupAttachments, options);
        }
export type CheckupAttachmentsQueryHookResult = ReturnType<typeof useCheckupAttachmentsQuery>;
export type CheckupAttachmentsLazyQueryHookResult = ReturnType<typeof useCheckupAttachmentsLazyQuery>;
export function useCheckupConfigsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CheckupConfigsQuery, import('./api').CheckupConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupConfigsQuery, import('./api').CheckupConfigsQueryVariables>(Operations.CheckupConfigs, options);
      }
export function useCheckupConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupConfigsQuery, import('./api').CheckupConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupConfigsQuery, import('./api').CheckupConfigsQueryVariables>(Operations.CheckupConfigs, options);
        }
export type CheckupConfigsQueryHookResult = ReturnType<typeof useCheckupConfigsQuery>;
export type CheckupConfigsLazyQueryHookResult = ReturnType<typeof useCheckupConfigsLazyQuery>;
export function useCheckupConfigQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CheckupConfigQuery, import('./api').CheckupConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupConfigQuery, import('./api').CheckupConfigQueryVariables>(Operations.CheckupConfig, options);
      }
export function useCheckupConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupConfigQuery, import('./api').CheckupConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupConfigQuery, import('./api').CheckupConfigQueryVariables>(Operations.CheckupConfig, options);
        }
export type CheckupConfigQueryHookResult = ReturnType<typeof useCheckupConfigQuery>;
export type CheckupConfigLazyQueryHookResult = ReturnType<typeof useCheckupConfigLazyQuery>;
export function useServiceCheckupKindsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ServiceCheckupKindsQuery, import('./api').ServiceCheckupKindsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ServiceCheckupKindsQuery, import('./api').ServiceCheckupKindsQueryVariables>(Operations.ServiceCheckupKinds, options);
      }
export function useServiceCheckupKindsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ServiceCheckupKindsQuery, import('./api').ServiceCheckupKindsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ServiceCheckupKindsQuery, import('./api').ServiceCheckupKindsQueryVariables>(Operations.ServiceCheckupKinds, options);
        }
export type ServiceCheckupKindsQueryHookResult = ReturnType<typeof useServiceCheckupKindsQuery>;
export type ServiceCheckupKindsLazyQueryHookResult = ReturnType<typeof useServiceCheckupKindsLazyQuery>;
export function useServiceCheckupKindQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ServiceCheckupKindQuery, import('./api').ServiceCheckupKindQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ServiceCheckupKindQuery, import('./api').ServiceCheckupKindQueryVariables>(Operations.ServiceCheckupKind, options);
      }
export function useServiceCheckupKindLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ServiceCheckupKindQuery, import('./api').ServiceCheckupKindQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ServiceCheckupKindQuery, import('./api').ServiceCheckupKindQueryVariables>(Operations.ServiceCheckupKind, options);
        }
export type ServiceCheckupKindQueryHookResult = ReturnType<typeof useServiceCheckupKindQuery>;
export type ServiceCheckupKindLazyQueryHookResult = ReturnType<typeof useServiceCheckupKindLazyQuery>;
export function useServiceCheckupKindByCodeQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ServiceCheckupKindByCodeQuery, import('./api').ServiceCheckupKindByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ServiceCheckupKindByCodeQuery, import('./api').ServiceCheckupKindByCodeQueryVariables>(Operations.ServiceCheckupKindByCode, options);
      }
export function useServiceCheckupKindByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ServiceCheckupKindByCodeQuery, import('./api').ServiceCheckupKindByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ServiceCheckupKindByCodeQuery, import('./api').ServiceCheckupKindByCodeQueryVariables>(Operations.ServiceCheckupKindByCode, options);
        }
export type ServiceCheckupKindByCodeQueryHookResult = ReturnType<typeof useServiceCheckupKindByCodeQuery>;
export type ServiceCheckupKindByCodeLazyQueryHookResult = ReturnType<typeof useServiceCheckupKindByCodeLazyQuery>;
export function useClientCheckupsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ClientCheckupsQuery, import('./api').ClientCheckupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientCheckupsQuery, import('./api').ClientCheckupsQueryVariables>(Operations.ClientCheckups, options);
      }
export function useClientCheckupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientCheckupsQuery, import('./api').ClientCheckupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientCheckupsQuery, import('./api').ClientCheckupsQueryVariables>(Operations.ClientCheckups, options);
        }
export type ClientCheckupsQueryHookResult = ReturnType<typeof useClientCheckupsQuery>;
export type ClientCheckupsLazyQueryHookResult = ReturnType<typeof useClientCheckupsLazyQuery>;
export function useClientCheckupQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ClientCheckupQuery, import('./api').ClientCheckupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientCheckupQuery, import('./api').ClientCheckupQueryVariables>(Operations.ClientCheckup, options);
      }
export function useClientCheckupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientCheckupQuery, import('./api').ClientCheckupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientCheckupQuery, import('./api').ClientCheckupQueryVariables>(Operations.ClientCheckup, options);
        }
export type ClientCheckupQueryHookResult = ReturnType<typeof useClientCheckupQuery>;
export type ClientCheckupLazyQueryHookResult = ReturnType<typeof useClientCheckupLazyQuery>;
export function useServiceCheckupsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ServiceCheckupsQuery, import('./api').ServiceCheckupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ServiceCheckupsQuery, import('./api').ServiceCheckupsQueryVariables>(Operations.ServiceCheckups, options);
      }
export function useServiceCheckupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ServiceCheckupsQuery, import('./api').ServiceCheckupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ServiceCheckupsQuery, import('./api').ServiceCheckupsQueryVariables>(Operations.ServiceCheckups, options);
        }
export type ServiceCheckupsQueryHookResult = ReturnType<typeof useServiceCheckupsQuery>;
export type ServiceCheckupsLazyQueryHookResult = ReturnType<typeof useServiceCheckupsLazyQuery>;
export function useServiceCheckupsPagginationQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ServiceCheckupsPagginationQuery, import('./api').ServiceCheckupsPagginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ServiceCheckupsPagginationQuery, import('./api').ServiceCheckupsPagginationQueryVariables>(Operations.ServiceCheckupsPaggination, options);
      }
export function useServiceCheckupsPagginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ServiceCheckupsPagginationQuery, import('./api').ServiceCheckupsPagginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ServiceCheckupsPagginationQuery, import('./api').ServiceCheckupsPagginationQueryVariables>(Operations.ServiceCheckupsPaggination, options);
        }
export type ServiceCheckupsPagginationQueryHookResult = ReturnType<typeof useServiceCheckupsPagginationQuery>;
export type ServiceCheckupsPagginationLazyQueryHookResult = ReturnType<typeof useServiceCheckupsPagginationLazyQuery>;
export function useServiceCheckupQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ServiceCheckupQuery, import('./api').ServiceCheckupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ServiceCheckupQuery, import('./api').ServiceCheckupQueryVariables>(Operations.ServiceCheckup, options);
      }
export function useServiceCheckupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ServiceCheckupQuery, import('./api').ServiceCheckupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ServiceCheckupQuery, import('./api').ServiceCheckupQueryVariables>(Operations.ServiceCheckup, options);
        }
export type ServiceCheckupQueryHookResult = ReturnType<typeof useServiceCheckupQuery>;
export type ServiceCheckupLazyQueryHookResult = ReturnType<typeof useServiceCheckupLazyQuery>;
export function useVeriffCheckupQuery(baseOptions: Apollo.QueryHookOptions<import('./api').VeriffCheckupQuery, import('./api').VeriffCheckupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').VeriffCheckupQuery, import('./api').VeriffCheckupQueryVariables>(Operations.VeriffCheckup, options);
      }
export function useVeriffCheckupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').VeriffCheckupQuery, import('./api').VeriffCheckupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').VeriffCheckupQuery, import('./api').VeriffCheckupQueryVariables>(Operations.VeriffCheckup, options);
        }
export type VeriffCheckupQueryHookResult = ReturnType<typeof useVeriffCheckupQuery>;
export type VeriffCheckupLazyQueryHookResult = ReturnType<typeof useVeriffCheckupLazyQuery>;
export function useCheckupsReportQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CheckupsReportQuery, import('./api').CheckupsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupsReportQuery, import('./api').CheckupsReportQueryVariables>(Operations.CheckupsReport, options);
      }
export function useCheckupsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupsReportQuery, import('./api').CheckupsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupsReportQuery, import('./api').CheckupsReportQueryVariables>(Operations.CheckupsReport, options);
        }
export type CheckupsReportQueryHookResult = ReturnType<typeof useCheckupsReportQuery>;
export type CheckupsReportLazyQueryHookResult = ReturnType<typeof useCheckupsReportLazyQuery>;
export function useDictionariesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').DictionariesQuery, import('./api').DictionariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').DictionariesQuery, import('./api').DictionariesQueryVariables>(Operations.Dictionaries, options);
      }
export function useDictionariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').DictionariesQuery, import('./api').DictionariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').DictionariesQuery, import('./api').DictionariesQueryVariables>(Operations.Dictionaries, options);
        }
export type DictionariesQueryHookResult = ReturnType<typeof useDictionariesQuery>;
export type DictionariesLazyQueryHookResult = ReturnType<typeof useDictionariesLazyQuery>;
export function useFincenListQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').FincenListQuery, import('./api').FincenListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FincenListQuery, import('./api').FincenListQueryVariables>(Operations.FincenList, options);
      }
export function useFincenListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FincenListQuery, import('./api').FincenListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FincenListQuery, import('./api').FincenListQueryVariables>(Operations.FincenList, options);
        }
export type FincenListQueryHookResult = ReturnType<typeof useFincenListQuery>;
export type FincenListLazyQueryHookResult = ReturnType<typeof useFincenListLazyQuery>;
export function useFincenPersonalRecordsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').FincenPersonalRecordsQuery, import('./api').FincenPersonalRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FincenPersonalRecordsQuery, import('./api').FincenPersonalRecordsQueryVariables>(Operations.FincenPersonalRecords, options);
      }
export function useFincenPersonalRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FincenPersonalRecordsQuery, import('./api').FincenPersonalRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FincenPersonalRecordsQuery, import('./api').FincenPersonalRecordsQueryVariables>(Operations.FincenPersonalRecords, options);
        }
export type FincenPersonalRecordsQueryHookResult = ReturnType<typeof useFincenPersonalRecordsQuery>;
export type FincenPersonalRecordsLazyQueryHookResult = ReturnType<typeof useFincenPersonalRecordsLazyQuery>;
export function useFincenBusinessRecordsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').FincenBusinessRecordsQuery, import('./api').FincenBusinessRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FincenBusinessRecordsQuery, import('./api').FincenBusinessRecordsQueryVariables>(Operations.FincenBusinessRecords, options);
      }
export function useFincenBusinessRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FincenBusinessRecordsQuery, import('./api').FincenBusinessRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FincenBusinessRecordsQuery, import('./api').FincenBusinessRecordsQueryVariables>(Operations.FincenBusinessRecords, options);
        }
export type FincenBusinessRecordsQueryHookResult = ReturnType<typeof useFincenBusinessRecordsQuery>;
export type FincenBusinessRecordsLazyQueryHookResult = ReturnType<typeof useFincenBusinessRecordsLazyQuery>;
export function useFincenCheckupsReportQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').FincenCheckupsReportQuery, import('./api').FincenCheckupsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FincenCheckupsReportQuery, import('./api').FincenCheckupsReportQueryVariables>(Operations.FincenCheckupsReport, options);
      }
export function useFincenCheckupsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FincenCheckupsReportQuery, import('./api').FincenCheckupsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FincenCheckupsReportQuery, import('./api').FincenCheckupsReportQueryVariables>(Operations.FincenCheckupsReport, options);
        }
export type FincenCheckupsReportQueryHookResult = ReturnType<typeof useFincenCheckupsReportQuery>;
export type FincenCheckupsReportLazyQueryHookResult = ReturnType<typeof useFincenCheckupsReportLazyQuery>;
export function useMassScreeningQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').MassScreeningQuery, import('./api').MassScreeningQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').MassScreeningQuery, import('./api').MassScreeningQueryVariables>(Operations.MassScreening, options);
      }
export function useMassScreeningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').MassScreeningQuery, import('./api').MassScreeningQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').MassScreeningQuery, import('./api').MassScreeningQueryVariables>(Operations.MassScreening, options);
        }
export type MassScreeningQueryHookResult = ReturnType<typeof useMassScreeningQuery>;
export type MassScreeningLazyQueryHookResult = ReturnType<typeof useMassScreeningLazyQuery>;
export function useMassScreeningDetailQuery(baseOptions: Apollo.QueryHookOptions<import('./api').MassScreeningDetailQuery, import('./api').MassScreeningDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').MassScreeningDetailQuery, import('./api').MassScreeningDetailQueryVariables>(Operations.MassScreeningDetail, options);
      }
export function useMassScreeningDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').MassScreeningDetailQuery, import('./api').MassScreeningDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').MassScreeningDetailQuery, import('./api').MassScreeningDetailQueryVariables>(Operations.MassScreeningDetail, options);
        }
export type MassScreeningDetailQueryHookResult = ReturnType<typeof useMassScreeningDetailQuery>;
export type MassScreeningDetailLazyQueryHookResult = ReturnType<typeof useMassScreeningDetailLazyQuery>;
export function usePpFincenListQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PpFincenListQuery, import('./api').PpFincenListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PpFincenListQuery, import('./api').PpFincenListQueryVariables>(Operations.PPFincenList, options);
      }
export function usePpFincenListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PpFincenListQuery, import('./api').PpFincenListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PpFincenListQuery, import('./api').PpFincenListQueryVariables>(Operations.PPFincenList, options);
        }
export type PpFincenListQueryHookResult = ReturnType<typeof usePpFincenListQuery>;
export type PpFincenListLazyQueryHookResult = ReturnType<typeof usePpFincenListLazyQuery>;
export function usePpFincenListItemQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PpFincenListItemQuery, import('./api').PpFincenListItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PpFincenListItemQuery, import('./api').PpFincenListItemQueryVariables>(Operations.PPFincenListItem, options);
      }
export function usePpFincenListItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PpFincenListItemQuery, import('./api').PpFincenListItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PpFincenListItemQuery, import('./api').PpFincenListItemQueryVariables>(Operations.PPFincenListItem, options);
        }
export type PpFincenListItemQueryHookResult = ReturnType<typeof usePpFincenListItemQuery>;
export type PpFincenListItemLazyQueryHookResult = ReturnType<typeof usePpFincenListItemLazyQuery>;
export function usePpFincenBusinessRecordsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').PpFincenBusinessRecordsQuery, import('./api').PpFincenBusinessRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PpFincenBusinessRecordsQuery, import('./api').PpFincenBusinessRecordsQueryVariables>(Operations.PPFincenBusinessRecords, options);
      }
export function usePpFincenBusinessRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PpFincenBusinessRecordsQuery, import('./api').PpFincenBusinessRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PpFincenBusinessRecordsQuery, import('./api').PpFincenBusinessRecordsQueryVariables>(Operations.PPFincenBusinessRecords, options);
        }
export type PpFincenBusinessRecordsQueryHookResult = ReturnType<typeof usePpFincenBusinessRecordsQuery>;
export type PpFincenBusinessRecordsLazyQueryHookResult = ReturnType<typeof usePpFincenBusinessRecordsLazyQuery>;
export function usePpFincenPersonalRecordsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').PpFincenPersonalRecordsQuery, import('./api').PpFincenPersonalRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PpFincenPersonalRecordsQuery, import('./api').PpFincenPersonalRecordsQueryVariables>(Operations.PPFincenPersonalRecords, options);
      }
export function usePpFincenPersonalRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PpFincenPersonalRecordsQuery, import('./api').PpFincenPersonalRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PpFincenPersonalRecordsQuery, import('./api').PpFincenPersonalRecordsQueryVariables>(Operations.PPFincenPersonalRecords, options);
        }
export type PpFincenPersonalRecordsQueryHookResult = ReturnType<typeof usePpFincenPersonalRecordsQuery>;
export type PpFincenPersonalRecordsLazyQueryHookResult = ReturnType<typeof usePpFincenPersonalRecordsLazyQuery>;
export function usePpScreeningQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PpScreeningQuery, import('./api').PpScreeningQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PpScreeningQuery, import('./api').PpScreeningQueryVariables>(Operations.PPScreening, options);
      }
export function usePpScreeningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PpScreeningQuery, import('./api').PpScreeningQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PpScreeningQuery, import('./api').PpScreeningQueryVariables>(Operations.PPScreening, options);
        }
export type PpScreeningQueryHookResult = ReturnType<typeof usePpScreeningQuery>;
export type PpScreeningLazyQueryHookResult = ReturnType<typeof usePpScreeningLazyQuery>;
export function usePpScreeningReportQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PpScreeningReportQuery, import('./api').PpScreeningReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PpScreeningReportQuery, import('./api').PpScreeningReportQueryVariables>(Operations.PPScreeningReport, options);
      }
export function usePpScreeningReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PpScreeningReportQuery, import('./api').PpScreeningReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PpScreeningReportQuery, import('./api').PpScreeningReportQueryVariables>(Operations.PPScreeningReport, options);
        }
export type PpScreeningReportQueryHookResult = ReturnType<typeof usePpScreeningReportQuery>;
export type PpScreeningReportLazyQueryHookResult = ReturnType<typeof usePpScreeningReportLazyQuery>;
export function usePpScreeningDetailsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').PpScreeningDetailsQuery, import('./api').PpScreeningDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PpScreeningDetailsQuery, import('./api').PpScreeningDetailsQueryVariables>(Operations.PPScreeningDetails, options);
      }
export function usePpScreeningDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PpScreeningDetailsQuery, import('./api').PpScreeningDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PpScreeningDetailsQuery, import('./api').PpScreeningDetailsQueryVariables>(Operations.PPScreeningDetails, options);
        }
export type PpScreeningDetailsQueryHookResult = ReturnType<typeof usePpScreeningDetailsQuery>;
export type PpScreeningDetailsLazyQueryHookResult = ReturnType<typeof usePpScreeningDetailsLazyQuery>;
export function useTranslationsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TranslationsQuery, import('./api').TranslationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TranslationsQuery, import('./api').TranslationsQueryVariables>(Operations.Translations, options);
      }
export function useTranslationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TranslationsQuery, import('./api').TranslationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TranslationsQuery, import('./api').TranslationsQueryVariables>(Operations.Translations, options);
        }
export type TranslationsQueryHookResult = ReturnType<typeof useTranslationsQuery>;
export type TranslationsLazyQueryHookResult = ReturnType<typeof useTranslationsLazyQuery>;
export function useClientDictionariesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ClientDictionariesQuery, import('./api').ClientDictionariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientDictionariesQuery, import('./api').ClientDictionariesQueryVariables>(Operations.ClientDictionaries, options);
      }
export function useClientDictionariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientDictionariesQuery, import('./api').ClientDictionariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientDictionariesQuery, import('./api').ClientDictionariesQueryVariables>(Operations.ClientDictionaries, options);
        }
export type ClientDictionariesQueryHookResult = ReturnType<typeof useClientDictionariesQuery>;
export type ClientDictionariesLazyQueryHookResult = ReturnType<typeof useClientDictionariesLazyQuery>;
export function useClientDictionaryQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ClientDictionaryQuery, import('./api').ClientDictionaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ClientDictionaryQuery, import('./api').ClientDictionaryQueryVariables>(Operations.ClientDictionary, options);
      }
export function useClientDictionaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ClientDictionaryQuery, import('./api').ClientDictionaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ClientDictionaryQuery, import('./api').ClientDictionaryQueryVariables>(Operations.ClientDictionary, options);
        }
export type ClientDictionaryQueryHookResult = ReturnType<typeof useClientDictionaryQuery>;
export type ClientDictionaryLazyQueryHookResult = ReturnType<typeof useClientDictionaryLazyQuery>;
export function useCasesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CasesQuery, import('./api').CasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CasesQuery, import('./api').CasesQueryVariables>(Operations.Cases, options);
      }
export function useCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CasesQuery, import('./api').CasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CasesQuery, import('./api').CasesQueryVariables>(Operations.Cases, options);
        }
export type CasesQueryHookResult = ReturnType<typeof useCasesQuery>;
export type CasesLazyQueryHookResult = ReturnType<typeof useCasesLazyQuery>;
export function useCaseQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseQuery, import('./api').CaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseQuery, import('./api').CaseQueryVariables>(Operations.Case, options);
      }
export function useCaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseQuery, import('./api').CaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseQuery, import('./api').CaseQueryVariables>(Operations.Case, options);
        }
export type CaseQueryHookResult = ReturnType<typeof useCaseQuery>;
export type CaseLazyQueryHookResult = ReturnType<typeof useCaseLazyQuery>;
export function useCaseSectionsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseSectionsQuery, import('./api').CaseSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseSectionsQuery, import('./api').CaseSectionsQueryVariables>(Operations.CaseSections, options);
      }
export function useCaseSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseSectionsQuery, import('./api').CaseSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseSectionsQuery, import('./api').CaseSectionsQueryVariables>(Operations.CaseSections, options);
        }
export type CaseSectionsQueryHookResult = ReturnType<typeof useCaseSectionsQuery>;
export type CaseSectionsLazyQueryHookResult = ReturnType<typeof useCaseSectionsLazyQuery>;
export function useCaseSectionTabsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseSectionTabsQuery, import('./api').CaseSectionTabsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseSectionTabsQuery, import('./api').CaseSectionTabsQueryVariables>(Operations.CaseSectionTabs, options);
      }
export function useCaseSectionTabsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseSectionTabsQuery, import('./api').CaseSectionTabsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseSectionTabsQuery, import('./api').CaseSectionTabsQueryVariables>(Operations.CaseSectionTabs, options);
        }
export type CaseSectionTabsQueryHookResult = ReturnType<typeof useCaseSectionTabsQuery>;
export type CaseSectionTabsLazyQueryHookResult = ReturnType<typeof useCaseSectionTabsLazyQuery>;
export function useCaseSectionQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseSectionQuery, import('./api').CaseSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseSectionQuery, import('./api').CaseSectionQueryVariables>(Operations.CaseSection, options);
      }
export function useCaseSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseSectionQuery, import('./api').CaseSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseSectionQuery, import('./api').CaseSectionQueryVariables>(Operations.CaseSection, options);
        }
export type CaseSectionQueryHookResult = ReturnType<typeof useCaseSectionQuery>;
export type CaseSectionLazyQueryHookResult = ReturnType<typeof useCaseSectionLazyQuery>;
export function useCaseSectionIdenfyResultQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseSectionIdenfyResultQuery, import('./api').CaseSectionIdenfyResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseSectionIdenfyResultQuery, import('./api').CaseSectionIdenfyResultQueryVariables>(Operations.CaseSectionIdenfyResult, options);
      }
export function useCaseSectionIdenfyResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseSectionIdenfyResultQuery, import('./api').CaseSectionIdenfyResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseSectionIdenfyResultQuery, import('./api').CaseSectionIdenfyResultQueryVariables>(Operations.CaseSectionIdenfyResult, options);
        }
export type CaseSectionIdenfyResultQueryHookResult = ReturnType<typeof useCaseSectionIdenfyResultQuery>;
export type CaseSectionIdenfyResultLazyQueryHookResult = ReturnType<typeof useCaseSectionIdenfyResultLazyQuery>;
export function useCaseSharingsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CaseSharingsQuery, import('./api').CaseSharingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseSharingsQuery, import('./api').CaseSharingsQueryVariables>(Operations.CaseSharings, options);
      }
export function useCaseSharingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseSharingsQuery, import('./api').CaseSharingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseSharingsQuery, import('./api').CaseSharingsQueryVariables>(Operations.CaseSharings, options);
        }
export type CaseSharingsQueryHookResult = ReturnType<typeof useCaseSharingsQuery>;
export type CaseSharingsLazyQueryHookResult = ReturnType<typeof useCaseSharingsLazyQuery>;
export function useCaseRoleSharingsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CaseRoleSharingsQuery, import('./api').CaseRoleSharingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseRoleSharingsQuery, import('./api').CaseRoleSharingsQueryVariables>(Operations.CaseRoleSharings, options);
      }
export function useCaseRoleSharingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseRoleSharingsQuery, import('./api').CaseRoleSharingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseRoleSharingsQuery, import('./api').CaseRoleSharingsQueryVariables>(Operations.CaseRoleSharings, options);
        }
export type CaseRoleSharingsQueryHookResult = ReturnType<typeof useCaseRoleSharingsQuery>;
export type CaseRoleSharingsLazyQueryHookResult = ReturnType<typeof useCaseRoleSharingsLazyQuery>;
export function useCaseSectionTabQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseSectionTabQuery, import('./api').CaseSectionTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseSectionTabQuery, import('./api').CaseSectionTabQueryVariables>(Operations.CaseSectionTab, options);
      }
export function useCaseSectionTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseSectionTabQuery, import('./api').CaseSectionTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseSectionTabQuery, import('./api').CaseSectionTabQueryVariables>(Operations.CaseSectionTab, options);
        }
export type CaseSectionTabQueryHookResult = ReturnType<typeof useCaseSectionTabQuery>;
export type CaseSectionTabLazyQueryHookResult = ReturnType<typeof useCaseSectionTabLazyQuery>;
export function useCaseRejectionReasonQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CaseRejectionReasonQuery, import('./api').CaseRejectionReasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseRejectionReasonQuery, import('./api').CaseRejectionReasonQueryVariables>(Operations.CaseRejectionReason, options);
      }
export function useCaseRejectionReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseRejectionReasonQuery, import('./api').CaseRejectionReasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseRejectionReasonQuery, import('./api').CaseRejectionReasonQueryVariables>(Operations.CaseRejectionReason, options);
        }
export type CaseRejectionReasonQueryHookResult = ReturnType<typeof useCaseRejectionReasonQuery>;
export type CaseRejectionReasonLazyQueryHookResult = ReturnType<typeof useCaseRejectionReasonLazyQuery>;
export function useDividedSearchQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').DividedSearchQuery, import('./api').DividedSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').DividedSearchQuery, import('./api').DividedSearchQueryVariables>(Operations.DividedSearch, options);
      }
export function useDividedSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').DividedSearchQuery, import('./api').DividedSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').DividedSearchQuery, import('./api').DividedSearchQueryVariables>(Operations.DividedSearch, options);
        }
export type DividedSearchQueryHookResult = ReturnType<typeof useDividedSearchQuery>;
export type DividedSearchLazyQueryHookResult = ReturnType<typeof useDividedSearchLazyQuery>;
export function useCasesReportQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CasesReportQuery, import('./api').CasesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CasesReportQuery, import('./api').CasesReportQueryVariables>(Operations.CasesReport, options);
      }
export function useCasesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CasesReportQuery, import('./api').CasesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CasesReportQuery, import('./api').CasesReportQueryVariables>(Operations.CasesReport, options);
        }
export type CasesReportQueryHookResult = ReturnType<typeof useCasesReportQuery>;
export type CasesReportLazyQueryHookResult = ReturnType<typeof useCasesReportLazyQuery>;
export function useCaseSummariesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseSummariesQuery, import('./api').CaseSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseSummariesQuery, import('./api').CaseSummariesQueryVariables>(Operations.CaseSummaries, options);
      }
export function useCaseSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseSummariesQuery, import('./api').CaseSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseSummariesQuery, import('./api').CaseSummariesQueryVariables>(Operations.CaseSummaries, options);
        }
export type CaseSummariesQueryHookResult = ReturnType<typeof useCaseSummariesQuery>;
export type CaseSummariesLazyQueryHookResult = ReturnType<typeof useCaseSummariesLazyQuery>;
export function useCommentsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CommentsQuery, import('./api').CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CommentsQuery, import('./api').CommentsQueryVariables>(Operations.Comments, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CommentsQuery, import('./api').CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CommentsQuery, import('./api').CommentsQueryVariables>(Operations.Comments, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export function useCustomerDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CustomerDocumentsQuery, import('./api').CustomerDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CustomerDocumentsQuery, import('./api').CustomerDocumentsQueryVariables>(Operations.CustomerDocuments, options);
      }
export function useCustomerDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CustomerDocumentsQuery, import('./api').CustomerDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CustomerDocumentsQuery, import('./api').CustomerDocumentsQueryVariables>(Operations.CustomerDocuments, options);
        }
export type CustomerDocumentsQueryHookResult = ReturnType<typeof useCustomerDocumentsQuery>;
export type CustomerDocumentsLazyQueryHookResult = ReturnType<typeof useCustomerDocumentsLazyQuery>;
export function useCustomerDocumentQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CustomerDocumentQuery, import('./api').CustomerDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CustomerDocumentQuery, import('./api').CustomerDocumentQueryVariables>(Operations.CustomerDocument, options);
      }
export function useCustomerDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CustomerDocumentQuery, import('./api').CustomerDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CustomerDocumentQuery, import('./api').CustomerDocumentQueryVariables>(Operations.CustomerDocument, options);
        }
export type CustomerDocumentQueryHookResult = ReturnType<typeof useCustomerDocumentQuery>;
export type CustomerDocumentLazyQueryHookResult = ReturnType<typeof useCustomerDocumentLazyQuery>;
export function useDataRetentionConfigsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').DataRetentionConfigsQuery, import('./api').DataRetentionConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').DataRetentionConfigsQuery, import('./api').DataRetentionConfigsQueryVariables>(Operations.DataRetentionConfigs, options);
      }
export function useDataRetentionConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').DataRetentionConfigsQuery, import('./api').DataRetentionConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').DataRetentionConfigsQuery, import('./api').DataRetentionConfigsQueryVariables>(Operations.DataRetentionConfigs, options);
        }
export type DataRetentionConfigsQueryHookResult = ReturnType<typeof useDataRetentionConfigsQuery>;
export type DataRetentionConfigsLazyQueryHookResult = ReturnType<typeof useDataRetentionConfigsLazyQuery>;
export function useDataRetentionPlansQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').DataRetentionPlansQuery, import('./api').DataRetentionPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').DataRetentionPlansQuery, import('./api').DataRetentionPlansQueryVariables>(Operations.DataRetentionPlans, options);
      }
export function useDataRetentionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').DataRetentionPlansQuery, import('./api').DataRetentionPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').DataRetentionPlansQuery, import('./api').DataRetentionPlansQueryVariables>(Operations.DataRetentionPlans, options);
        }
export type DataRetentionPlansQueryHookResult = ReturnType<typeof useDataRetentionPlansQuery>;
export type DataRetentionPlansLazyQueryHookResult = ReturnType<typeof useDataRetentionPlansLazyQuery>;
export function useDeviceInfosQuery(baseOptions: Apollo.QueryHookOptions<import('./api').DeviceInfosQuery, import('./api').DeviceInfosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').DeviceInfosQuery, import('./api').DeviceInfosQueryVariables>(Operations.DeviceInfos, options);
      }
export function useDeviceInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').DeviceInfosQuery, import('./api').DeviceInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').DeviceInfosQuery, import('./api').DeviceInfosQueryVariables>(Operations.DeviceInfos, options);
        }
export type DeviceInfosQueryHookResult = ReturnType<typeof useDeviceInfosQuery>;
export type DeviceInfosLazyQueryHookResult = ReturnType<typeof useDeviceInfosLazyQuery>;
export function useDictionaryQuery(baseOptions: Apollo.QueryHookOptions<import('./api').DictionaryQuery, import('./api').DictionaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').DictionaryQuery, import('./api').DictionaryQueryVariables>(Operations.Dictionary, options);
      }
export function useDictionaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').DictionaryQuery, import('./api').DictionaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').DictionaryQuery, import('./api').DictionaryQueryVariables>(Operations.Dictionary, options);
        }
export type DictionaryQueryHookResult = ReturnType<typeof useDictionaryQuery>;
export type DictionaryLazyQueryHookResult = ReturnType<typeof useDictionaryLazyQuery>;
export function useCryptoCurrencyDictionaryQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CryptoCurrencyDictionaryQuery, import('./api').CryptoCurrencyDictionaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CryptoCurrencyDictionaryQuery, import('./api').CryptoCurrencyDictionaryQueryVariables>(Operations.CryptoCurrencyDictionary, options);
      }
export function useCryptoCurrencyDictionaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CryptoCurrencyDictionaryQuery, import('./api').CryptoCurrencyDictionaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CryptoCurrencyDictionaryQuery, import('./api').CryptoCurrencyDictionaryQueryVariables>(Operations.CryptoCurrencyDictionary, options);
        }
export type CryptoCurrencyDictionaryQueryHookResult = ReturnType<typeof useCryptoCurrencyDictionaryQuery>;
export type CryptoCurrencyDictionaryLazyQueryHookResult = ReturnType<typeof useCryptoCurrencyDictionaryLazyQuery>;
export function useDocumentQuery(baseOptions: Apollo.QueryHookOptions<import('./api').DocumentQuery, import('./api').DocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').DocumentQuery, import('./api').DocumentQueryVariables>(Operations.Document, options);
      }
export function useDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').DocumentQuery, import('./api').DocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').DocumentQuery, import('./api').DocumentQueryVariables>(Operations.Document, options);
        }
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>;
export type DocumentLazyQueryHookResult = ReturnType<typeof useDocumentLazyQuery>;
export function useDownloadDocumentQuery(baseOptions: Apollo.QueryHookOptions<import('./api').DownloadDocumentQuery, import('./api').DownloadDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').DownloadDocumentQuery, import('./api').DownloadDocumentQueryVariables>(Operations.DownloadDocument, options);
      }
export function useDownloadDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').DownloadDocumentQuery, import('./api').DownloadDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').DownloadDocumentQuery, import('./api').DownloadDocumentQueryVariables>(Operations.DownloadDocument, options);
        }
export type DownloadDocumentQueryHookResult = ReturnType<typeof useDownloadDocumentQuery>;
export type DownloadDocumentLazyQueryHookResult = ReturnType<typeof useDownloadDocumentLazyQuery>;
export function useEddReportsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').EddReportsQuery, import('./api').EddReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').EddReportsQuery, import('./api').EddReportsQueryVariables>(Operations.EDDReports, options);
      }
export function useEddReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').EddReportsQuery, import('./api').EddReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').EddReportsQuery, import('./api').EddReportsQueryVariables>(Operations.EDDReports, options);
        }
export type EddReportsQueryHookResult = ReturnType<typeof useEddReportsQuery>;
export type EddReportsLazyQueryHookResult = ReturnType<typeof useEddReportsLazyQuery>;
export function useEddReportQuery(baseOptions: Apollo.QueryHookOptions<import('./api').EddReportQuery, import('./api').EddReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').EddReportQuery, import('./api').EddReportQueryVariables>(Operations.EDDReport, options);
      }
export function useEddReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').EddReportQuery, import('./api').EddReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').EddReportQuery, import('./api').EddReportQueryVariables>(Operations.EDDReport, options);
        }
export type EddReportQueryHookResult = ReturnType<typeof useEddReportQuery>;
export type EddReportLazyQueryHookResult = ReturnType<typeof useEddReportLazyQuery>;
export function useEddReportDocumentsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').EddReportDocumentsQuery, import('./api').EddReportDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').EddReportDocumentsQuery, import('./api').EddReportDocumentsQueryVariables>(Operations.EDDReportDocuments, options);
      }
export function useEddReportDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').EddReportDocumentsQuery, import('./api').EddReportDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').EddReportDocumentsQuery, import('./api').EddReportDocumentsQueryVariables>(Operations.EDDReportDocuments, options);
        }
export type EddReportDocumentsQueryHookResult = ReturnType<typeof useEddReportDocumentsQuery>;
export type EddReportDocumentsLazyQueryHookResult = ReturnType<typeof useEddReportDocumentsLazyQuery>;
export function useEddcsvReportQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').EddcsvReportQuery, import('./api').EddcsvReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').EddcsvReportQuery, import('./api').EddcsvReportQueryVariables>(Operations.EDDCSVReport, options);
      }
export function useEddcsvReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').EddcsvReportQuery, import('./api').EddcsvReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').EddcsvReportQuery, import('./api').EddcsvReportQueryVariables>(Operations.EDDCSVReport, options);
        }
export type EddcsvReportQueryHookResult = ReturnType<typeof useEddcsvReportQuery>;
export type EddcsvReportLazyQueryHookResult = ReturnType<typeof useEddcsvReportLazyQuery>;
export function useCustomerMessagesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CustomerMessagesQuery, import('./api').CustomerMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CustomerMessagesQuery, import('./api').CustomerMessagesQueryVariables>(Operations.CustomerMessages, options);
      }
export function useCustomerMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CustomerMessagesQuery, import('./api').CustomerMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CustomerMessagesQuery, import('./api').CustomerMessagesQueryVariables>(Operations.CustomerMessages, options);
        }
export type CustomerMessagesQueryHookResult = ReturnType<typeof useCustomerMessagesQuery>;
export type CustomerMessagesLazyQueryHookResult = ReturnType<typeof useCustomerMessagesLazyQuery>;
export function useCustomerMessageQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CustomerMessageQuery, import('./api').CustomerMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CustomerMessageQuery, import('./api').CustomerMessageQueryVariables>(Operations.CustomerMessage, options);
      }
export function useCustomerMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CustomerMessageQuery, import('./api').CustomerMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CustomerMessageQuery, import('./api').CustomerMessageQueryVariables>(Operations.CustomerMessage, options);
        }
export type CustomerMessageQueryHookResult = ReturnType<typeof useCustomerMessageQuery>;
export type CustomerMessageLazyQueryHookResult = ReturnType<typeof useCustomerMessageLazyQuery>;
export function useEmailTemplatesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').EmailTemplatesQuery, import('./api').EmailTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').EmailTemplatesQuery, import('./api').EmailTemplatesQueryVariables>(Operations.EmailTemplates, options);
      }
export function useEmailTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').EmailTemplatesQuery, import('./api').EmailTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').EmailTemplatesQuery, import('./api').EmailTemplatesQueryVariables>(Operations.EmailTemplates, options);
        }
export type EmailTemplatesQueryHookResult = ReturnType<typeof useEmailTemplatesQuery>;
export type EmailTemplatesLazyQueryHookResult = ReturnType<typeof useEmailTemplatesLazyQuery>;
export function useEmailTemplateQuery(baseOptions: Apollo.QueryHookOptions<import('./api').EmailTemplateQuery, import('./api').EmailTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').EmailTemplateQuery, import('./api').EmailTemplateQueryVariables>(Operations.EmailTemplate, options);
      }
export function useEmailTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').EmailTemplateQuery, import('./api').EmailTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').EmailTemplateQuery, import('./api').EmailTemplateQueryVariables>(Operations.EmailTemplate, options);
        }
export type EmailTemplateQueryHookResult = ReturnType<typeof useEmailTemplateQuery>;
export type EmailTemplateLazyQueryHookResult = ReturnType<typeof useEmailTemplateLazyQuery>;
export function useFieldUpdatesHistoryQuery(baseOptions: Apollo.QueryHookOptions<import('./api').FieldUpdatesHistoryQuery, import('./api').FieldUpdatesHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FieldUpdatesHistoryQuery, import('./api').FieldUpdatesHistoryQueryVariables>(Operations.FieldUpdatesHistory, options);
      }
export function useFieldUpdatesHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FieldUpdatesHistoryQuery, import('./api').FieldUpdatesHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FieldUpdatesHistoryQuery, import('./api').FieldUpdatesHistoryQueryVariables>(Operations.FieldUpdatesHistory, options);
        }
export type FieldUpdatesHistoryQueryHookResult = ReturnType<typeof useFieldUpdatesHistoryQuery>;
export type FieldUpdatesHistoryLazyQueryHookResult = ReturnType<typeof useFieldUpdatesHistoryLazyQuery>;
export function useFieldsPresetsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').FieldsPresetsQuery, import('./api').FieldsPresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FieldsPresetsQuery, import('./api').FieldsPresetsQueryVariables>(Operations.FieldsPresets, options);
      }
export function useFieldsPresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FieldsPresetsQuery, import('./api').FieldsPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FieldsPresetsQuery, import('./api').FieldsPresetsQueryVariables>(Operations.FieldsPresets, options);
        }
export type FieldsPresetsQueryHookResult = ReturnType<typeof useFieldsPresetsQuery>;
export type FieldsPresetsLazyQueryHookResult = ReturnType<typeof useFieldsPresetsLazyQuery>;
export function useFieldsPresetQuery(baseOptions: Apollo.QueryHookOptions<import('./api').FieldsPresetQuery, import('./api').FieldsPresetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FieldsPresetQuery, import('./api').FieldsPresetQueryVariables>(Operations.FieldsPreset, options);
      }
export function useFieldsPresetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FieldsPresetQuery, import('./api').FieldsPresetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FieldsPresetQuery, import('./api').FieldsPresetQueryVariables>(Operations.FieldsPreset, options);
        }
export type FieldsPresetQueryHookResult = ReturnType<typeof useFieldsPresetQuery>;
export type FieldsPresetLazyQueryHookResult = ReturnType<typeof useFieldsPresetLazyQuery>;
export function useFormsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').FormsQuery, import('./api').FormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FormsQuery, import('./api').FormsQueryVariables>(Operations.Forms, options);
      }
export function useFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FormsQuery, import('./api').FormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FormsQuery, import('./api').FormsQueryVariables>(Operations.Forms, options);
        }
export type FormsQueryHookResult = ReturnType<typeof useFormsQuery>;
export type FormsLazyQueryHookResult = ReturnType<typeof useFormsLazyQuery>;
export function useFormQuery(baseOptions: Apollo.QueryHookOptions<import('./api').FormQuery, import('./api').FormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FormQuery, import('./api').FormQueryVariables>(Operations.Form, options);
      }
export function useFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FormQuery, import('./api').FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FormQuery, import('./api').FormQueryVariables>(Operations.Form, options);
        }
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export function useFormInvitationsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').FormInvitationsQuery, import('./api').FormInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FormInvitationsQuery, import('./api').FormInvitationsQueryVariables>(Operations.FormInvitations, options);
      }
export function useFormInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FormInvitationsQuery, import('./api').FormInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FormInvitationsQuery, import('./api').FormInvitationsQueryVariables>(Operations.FormInvitations, options);
        }
export type FormInvitationsQueryHookResult = ReturnType<typeof useFormInvitationsQuery>;
export type FormInvitationsLazyQueryHookResult = ReturnType<typeof useFormInvitationsLazyQuery>;
export function useGlobalSearchEntriesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').GlobalSearchEntriesQuery, import('./api').GlobalSearchEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').GlobalSearchEntriesQuery, import('./api').GlobalSearchEntriesQueryVariables>(Operations.GlobalSearchEntries, options);
      }
export function useGlobalSearchEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').GlobalSearchEntriesQuery, import('./api').GlobalSearchEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').GlobalSearchEntriesQuery, import('./api').GlobalSearchEntriesQueryVariables>(Operations.GlobalSearchEntries, options);
        }
export type GlobalSearchEntriesQueryHookResult = ReturnType<typeof useGlobalSearchEntriesQuery>;
export type GlobalSearchEntriesLazyQueryHookResult = ReturnType<typeof useGlobalSearchEntriesLazyQuery>;
export function useGlobalSearchEntrieQuery(baseOptions: Apollo.QueryHookOptions<import('./api').GlobalSearchEntrieQuery, import('./api').GlobalSearchEntrieQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').GlobalSearchEntrieQuery, import('./api').GlobalSearchEntrieQueryVariables>(Operations.GlobalSearchEntrie, options);
      }
export function useGlobalSearchEntrieLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').GlobalSearchEntrieQuery, import('./api').GlobalSearchEntrieQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').GlobalSearchEntrieQuery, import('./api').GlobalSearchEntrieQueryVariables>(Operations.GlobalSearchEntrie, options);
        }
export type GlobalSearchEntrieQueryHookResult = ReturnType<typeof useGlobalSearchEntrieQuery>;
export type GlobalSearchEntrieLazyQueryHookResult = ReturnType<typeof useGlobalSearchEntrieLazyQuery>;
export function useIdentityVerificationsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').IdentityVerificationsQuery, import('./api').IdentityVerificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').IdentityVerificationsQuery, import('./api').IdentityVerificationsQueryVariables>(Operations.IdentityVerifications, options);
      }
export function useIdentityVerificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').IdentityVerificationsQuery, import('./api').IdentityVerificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').IdentityVerificationsQuery, import('./api').IdentityVerificationsQueryVariables>(Operations.IdentityVerifications, options);
        }
export type IdentityVerificationsQueryHookResult = ReturnType<typeof useIdentityVerificationsQuery>;
export type IdentityVerificationsLazyQueryHookResult = ReturnType<typeof useIdentityVerificationsLazyQuery>;
export function useIdentityVerificationQuery(baseOptions: Apollo.QueryHookOptions<import('./api').IdentityVerificationQuery, import('./api').IdentityVerificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').IdentityVerificationQuery, import('./api').IdentityVerificationQueryVariables>(Operations.IdentityVerification, options);
      }
export function useIdentityVerificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').IdentityVerificationQuery, import('./api').IdentityVerificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').IdentityVerificationQuery, import('./api').IdentityVerificationQueryVariables>(Operations.IdentityVerification, options);
        }
export type IdentityVerificationQueryHookResult = ReturnType<typeof useIdentityVerificationQuery>;
export type IdentityVerificationLazyQueryHookResult = ReturnType<typeof useIdentityVerificationLazyQuery>;
export function useInvitesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').InvitesQuery, import('./api').InvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').InvitesQuery, import('./api').InvitesQueryVariables>(Operations.Invites, options);
      }
export function useInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').InvitesQuery, import('./api').InvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').InvitesQuery, import('./api').InvitesQueryVariables>(Operations.Invites, options);
        }
export type InvitesQueryHookResult = ReturnType<typeof useInvitesQuery>;
export type InvitesLazyQueryHookResult = ReturnType<typeof useInvitesLazyQuery>;
export function useInviteQuery(baseOptions: Apollo.QueryHookOptions<import('./api').InviteQuery, import('./api').InviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').InviteQuery, import('./api').InviteQueryVariables>(Operations.Invite, options);
      }
export function useInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').InviteQuery, import('./api').InviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').InviteQuery, import('./api').InviteQueryVariables>(Operations.Invite, options);
        }
export type InviteQueryHookResult = ReturnType<typeof useInviteQuery>;
export type InviteLazyQueryHookResult = ReturnType<typeof useInviteLazyQuery>;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').MeQuery, import('./api').MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').MeQuery, import('./api').MeQueryVariables>(Operations.Me, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').MeQuery, import('./api').MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').MeQuery, import('./api').MeQueryVariables>(Operations.Me, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export function useRetentionMeQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').RetentionMeQuery, import('./api').RetentionMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RetentionMeQuery, import('./api').RetentionMeQueryVariables>(Operations.RetentionMe, options);
      }
export function useRetentionMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RetentionMeQuery, import('./api').RetentionMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RetentionMeQuery, import('./api').RetentionMeQueryVariables>(Operations.RetentionMe, options);
        }
export type RetentionMeQueryHookResult = ReturnType<typeof useRetentionMeQuery>;
export type RetentionMeLazyQueryHookResult = ReturnType<typeof useRetentionMeLazyQuery>;
export function useMyFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').MyFeaturesQuery, import('./api').MyFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').MyFeaturesQuery, import('./api').MyFeaturesQueryVariables>(Operations.MyFeatures, options);
      }
export function useMyFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').MyFeaturesQuery, import('./api').MyFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').MyFeaturesQuery, import('./api').MyFeaturesQueryVariables>(Operations.MyFeatures, options);
        }
export type MyFeaturesQueryHookResult = ReturnType<typeof useMyFeaturesQuery>;
export type MyFeaturesLazyQueryHookResult = ReturnType<typeof useMyFeaturesLazyQuery>;
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').NotificationsQuery, import('./api').NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').NotificationsQuery, import('./api').NotificationsQueryVariables>(Operations.Notifications, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').NotificationsQuery, import('./api').NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').NotificationsQuery, import('./api').NotificationsQueryVariables>(Operations.Notifications, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export function useNotificationConfigQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').NotificationConfigQuery, import('./api').NotificationConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').NotificationConfigQuery, import('./api').NotificationConfigQueryVariables>(Operations.NotificationConfig, options);
      }
export function useNotificationConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').NotificationConfigQuery, import('./api').NotificationConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').NotificationConfigQuery, import('./api').NotificationConfigQueryVariables>(Operations.NotificationConfig, options);
        }
export type NotificationConfigQueryHookResult = ReturnType<typeof useNotificationConfigQuery>;
export type NotificationConfigLazyQueryHookResult = ReturnType<typeof useNotificationConfigLazyQuery>;
export function useNotificationSubscriptionConfigQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').NotificationSubscriptionConfigQuery, import('./api').NotificationSubscriptionConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').NotificationSubscriptionConfigQuery, import('./api').NotificationSubscriptionConfigQueryVariables>(Operations.NotificationSubscriptionConfig, options);
      }
export function useNotificationSubscriptionConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').NotificationSubscriptionConfigQuery, import('./api').NotificationSubscriptionConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').NotificationSubscriptionConfigQuery, import('./api').NotificationSubscriptionConfigQueryVariables>(Operations.NotificationSubscriptionConfig, options);
        }
export type NotificationSubscriptionConfigQueryHookResult = ReturnType<typeof useNotificationSubscriptionConfigQuery>;
export type NotificationSubscriptionConfigLazyQueryHookResult = ReturnType<typeof useNotificationSubscriptionConfigLazyQuery>;
export function useNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').NotificationSettingsQuery, import('./api').NotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').NotificationSettingsQuery, import('./api').NotificationSettingsQueryVariables>(Operations.NotificationSettings, options);
      }
export function useNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').NotificationSettingsQuery, import('./api').NotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').NotificationSettingsQuery, import('./api').NotificationSettingsQueryVariables>(Operations.NotificationSettings, options);
        }
export type NotificationSettingsQueryHookResult = ReturnType<typeof useNotificationSettingsQuery>;
export type NotificationSettingsLazyQueryHookResult = ReturnType<typeof useNotificationSettingsLazyQuery>;
export function useDraftClientsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').DraftClientsQuery, import('./api').DraftClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').DraftClientsQuery, import('./api').DraftClientsQueryVariables>(Operations.DraftClients, options);
      }
export function useDraftClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').DraftClientsQuery, import('./api').DraftClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').DraftClientsQuery, import('./api').DraftClientsQueryVariables>(Operations.DraftClients, options);
        }
export type DraftClientsQueryHookResult = ReturnType<typeof useDraftClientsQuery>;
export type DraftClientsLazyQueryHookResult = ReturnType<typeof useDraftClientsLazyQuery>;
export function useOnboardingUrlQuery(baseOptions: Apollo.QueryHookOptions<import('./api').OnboardingUrlQuery, import('./api').OnboardingUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').OnboardingUrlQuery, import('./api').OnboardingUrlQueryVariables>(Operations.OnboardingUrl, options);
      }
export function useOnboardingUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').OnboardingUrlQuery, import('./api').OnboardingUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').OnboardingUrlQuery, import('./api').OnboardingUrlQueryVariables>(Operations.OnboardingUrl, options);
        }
export type OnboardingUrlQueryHookResult = ReturnType<typeof useOnboardingUrlQuery>;
export type OnboardingUrlLazyQueryHookResult = ReturnType<typeof useOnboardingUrlLazyQuery>;
export function useCaseReportQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseReportQuery, import('./api').CaseReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseReportQuery, import('./api').CaseReportQueryVariables>(Operations.CaseReport, options);
      }
export function useCaseReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseReportQuery, import('./api').CaseReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseReportQuery, import('./api').CaseReportQueryVariables>(Operations.CaseReport, options);
        }
export type CaseReportQueryHookResult = ReturnType<typeof useCaseReportQuery>;
export type CaseReportLazyQueryHookResult = ReturnType<typeof useCaseReportLazyQuery>;
export function useMyPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').MyPermissionsQuery, import('./api').MyPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').MyPermissionsQuery, import('./api').MyPermissionsQueryVariables>(Operations.MyPermissions, options);
      }
export function useMyPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').MyPermissionsQuery, import('./api').MyPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').MyPermissionsQuery, import('./api').MyPermissionsQueryVariables>(Operations.MyPermissions, options);
        }
export type MyPermissionsQueryHookResult = ReturnType<typeof useMyPermissionsQuery>;
export type MyPermissionsLazyQueryHookResult = ReturnType<typeof useMyPermissionsLazyQuery>;
export function usePingQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PingQuery, import('./api').PingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PingQuery, import('./api').PingQueryVariables>(Operations.Ping, options);
      }
export function usePingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PingQuery, import('./api').PingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PingQuery, import('./api').PingQueryVariables>(Operations.Ping, options);
        }
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export function usePotentialConnectionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PotentialConnectionsQuery, import('./api').PotentialConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PotentialConnectionsQuery, import('./api').PotentialConnectionsQueryVariables>(Operations.PotentialConnections, options);
      }
export function usePotentialConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PotentialConnectionsQuery, import('./api').PotentialConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PotentialConnectionsQuery, import('./api').PotentialConnectionsQueryVariables>(Operations.PotentialConnections, options);
        }
export type PotentialConnectionsQueryHookResult = ReturnType<typeof usePotentialConnectionsQuery>;
export type PotentialConnectionsLazyQueryHookResult = ReturnType<typeof usePotentialConnectionsLazyQuery>;
export function usePpExternalRefsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PpExternalRefsQuery, import('./api').PpExternalRefsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PpExternalRefsQuery, import('./api').PpExternalRefsQueryVariables>(Operations.PpExternalRefs, options);
      }
export function usePpExternalRefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PpExternalRefsQuery, import('./api').PpExternalRefsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PpExternalRefsQuery, import('./api').PpExternalRefsQueryVariables>(Operations.PpExternalRefs, options);
        }
export type PpExternalRefsQueryHookResult = ReturnType<typeof usePpExternalRefsQuery>;
export type PpExternalRefsLazyQueryHookResult = ReturnType<typeof usePpExternalRefsLazyQuery>;
export function useExportTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ExportTemplatesQuery, import('./api').ExportTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ExportTemplatesQuery, import('./api').ExportTemplatesQueryVariables>(Operations.ExportTemplates, options);
      }
export function useExportTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ExportTemplatesQuery, import('./api').ExportTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ExportTemplatesQuery, import('./api').ExportTemplatesQueryVariables>(Operations.ExportTemplates, options);
        }
export type ExportTemplatesQueryHookResult = ReturnType<typeof useExportTemplatesQuery>;
export type ExportTemplatesLazyQueryHookResult = ReturnType<typeof useExportTemplatesLazyQuery>;
export function useExportRequestQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ExportRequestQuery, import('./api').ExportRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ExportRequestQuery, import('./api').ExportRequestQueryVariables>(Operations.ExportRequest, options);
      }
export function useExportRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ExportRequestQuery, import('./api').ExportRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ExportRequestQuery, import('./api').ExportRequestQueryVariables>(Operations.ExportRequest, options);
        }
export type ExportRequestQueryHookResult = ReturnType<typeof useExportRequestQuery>;
export type ExportRequestLazyQueryHookResult = ReturnType<typeof useExportRequestLazyQuery>;
export function useExportRequestsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ExportRequestsQuery, import('./api').ExportRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ExportRequestsQuery, import('./api').ExportRequestsQueryVariables>(Operations.ExportRequests, options);
      }
export function useExportRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ExportRequestsQuery, import('./api').ExportRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ExportRequestsQuery, import('./api').ExportRequestsQueryVariables>(Operations.ExportRequests, options);
        }
export type ExportRequestsQueryHookResult = ReturnType<typeof useExportRequestsQuery>;
export type ExportRequestsLazyQueryHookResult = ReturnType<typeof useExportRequestsLazyQuery>;
export function usePricingClientBalanceQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PricingClientBalanceQuery, import('./api').PricingClientBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PricingClientBalanceQuery, import('./api').PricingClientBalanceQueryVariables>(Operations.PricingClientBalance, options);
      }
export function usePricingClientBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PricingClientBalanceQuery, import('./api').PricingClientBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PricingClientBalanceQuery, import('./api').PricingClientBalanceQueryVariables>(Operations.PricingClientBalance, options);
        }
export type PricingClientBalanceQueryHookResult = ReturnType<typeof usePricingClientBalanceQuery>;
export type PricingClientBalanceLazyQueryHookResult = ReturnType<typeof usePricingClientBalanceLazyQuery>;
export function usePricingClientFeatureDictionaryQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PricingClientFeatureDictionaryQuery, import('./api').PricingClientFeatureDictionaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PricingClientFeatureDictionaryQuery, import('./api').PricingClientFeatureDictionaryQueryVariables>(Operations.PricingClientFeatureDictionary, options);
      }
export function usePricingClientFeatureDictionaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PricingClientFeatureDictionaryQuery, import('./api').PricingClientFeatureDictionaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PricingClientFeatureDictionaryQuery, import('./api').PricingClientFeatureDictionaryQueryVariables>(Operations.PricingClientFeatureDictionary, options);
        }
export type PricingClientFeatureDictionaryQueryHookResult = ReturnType<typeof usePricingClientFeatureDictionaryQuery>;
export type PricingClientFeatureDictionaryLazyQueryHookResult = ReturnType<typeof usePricingClientFeatureDictionaryLazyQuery>;
export function usePricingClientPlansQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PricingClientPlansQuery, import('./api').PricingClientPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PricingClientPlansQuery, import('./api').PricingClientPlansQueryVariables>(Operations.PricingClientPlans, options);
      }
export function usePricingClientPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PricingClientPlansQuery, import('./api').PricingClientPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PricingClientPlansQuery, import('./api').PricingClientPlansQueryVariables>(Operations.PricingClientPlans, options);
        }
export type PricingClientPlansQueryHookResult = ReturnType<typeof usePricingClientPlansQuery>;
export type PricingClientPlansLazyQueryHookResult = ReturnType<typeof usePricingClientPlansLazyQuery>;
export function usePricingClientSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PricingClientSubscriptionsQuery, import('./api').PricingClientSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PricingClientSubscriptionsQuery, import('./api').PricingClientSubscriptionsQueryVariables>(Operations.PricingClientSubscriptions, options);
      }
export function usePricingClientSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PricingClientSubscriptionsQuery, import('./api').PricingClientSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PricingClientSubscriptionsQuery, import('./api').PricingClientSubscriptionsQueryVariables>(Operations.PricingClientSubscriptions, options);
        }
export type PricingClientSubscriptionsQueryHookResult = ReturnType<typeof usePricingClientSubscriptionsQuery>;
export type PricingClientSubscriptionsLazyQueryHookResult = ReturnType<typeof usePricingClientSubscriptionsLazyQuery>;
export function usePricingClientTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PricingClientTransactionsQuery, import('./api').PricingClientTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PricingClientTransactionsQuery, import('./api').PricingClientTransactionsQueryVariables>(Operations.PricingClientTransactions, options);
      }
export function usePricingClientTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PricingClientTransactionsQuery, import('./api').PricingClientTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PricingClientTransactionsQuery, import('./api').PricingClientTransactionsQueryVariables>(Operations.PricingClientTransactions, options);
        }
export type PricingClientTransactionsQueryHookResult = ReturnType<typeof usePricingClientTransactionsQuery>;
export type PricingClientTransactionsLazyQueryHookResult = ReturnType<typeof usePricingClientTransactionsLazyQuery>;
export function usePricingPlanClientsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PricingPlanClientsQuery, import('./api').PricingPlanClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PricingPlanClientsQuery, import('./api').PricingPlanClientsQueryVariables>(Operations.PricingPlanClients, options);
      }
export function usePricingPlanClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PricingPlanClientsQuery, import('./api').PricingPlanClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PricingPlanClientsQuery, import('./api').PricingPlanClientsQueryVariables>(Operations.PricingPlanClients, options);
        }
export type PricingPlanClientsQueryHookResult = ReturnType<typeof usePricingPlanClientsQuery>;
export type PricingPlanClientsLazyQueryHookResult = ReturnType<typeof usePricingPlanClientsLazyQuery>;
export function usePricingFeatureTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PricingFeatureTemplatesQuery, import('./api').PricingFeatureTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PricingFeatureTemplatesQuery, import('./api').PricingFeatureTemplatesQueryVariables>(Operations.PricingFeatureTemplates, options);
      }
export function usePricingFeatureTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PricingFeatureTemplatesQuery, import('./api').PricingFeatureTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PricingFeatureTemplatesQuery, import('./api').PricingFeatureTemplatesQueryVariables>(Operations.PricingFeatureTemplates, options);
        }
export type PricingFeatureTemplatesQueryHookResult = ReturnType<typeof usePricingFeatureTemplatesQuery>;
export type PricingFeatureTemplatesLazyQueryHookResult = ReturnType<typeof usePricingFeatureTemplatesLazyQuery>;
export function usePricingFeatureDictionaryQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PricingFeatureDictionaryQuery, import('./api').PricingFeatureDictionaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PricingFeatureDictionaryQuery, import('./api').PricingFeatureDictionaryQueryVariables>(Operations.PricingFeatureDictionary, options);
      }
export function usePricingFeatureDictionaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PricingFeatureDictionaryQuery, import('./api').PricingFeatureDictionaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PricingFeatureDictionaryQuery, import('./api').PricingFeatureDictionaryQueryVariables>(Operations.PricingFeatureDictionary, options);
        }
export type PricingFeatureDictionaryQueryHookResult = ReturnType<typeof usePricingFeatureDictionaryQuery>;
export type PricingFeatureDictionaryLazyQueryHookResult = ReturnType<typeof usePricingFeatureDictionaryLazyQuery>;
export function usePricingPlansQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PricingPlansQuery, import('./api').PricingPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PricingPlansQuery, import('./api').PricingPlansQueryVariables>(Operations.PricingPlans, options);
      }
export function usePricingPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PricingPlansQuery, import('./api').PricingPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PricingPlansQuery, import('./api').PricingPlansQueryVariables>(Operations.PricingPlans, options);
        }
export type PricingPlansQueryHookResult = ReturnType<typeof usePricingPlansQuery>;
export type PricingPlansLazyQueryHookResult = ReturnType<typeof usePricingPlansLazyQuery>;
export function usePricingPlanQuery(baseOptions: Apollo.QueryHookOptions<import('./api').PricingPlanQuery, import('./api').PricingPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PricingPlanQuery, import('./api').PricingPlanQueryVariables>(Operations.PricingPlan, options);
      }
export function usePricingPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PricingPlanQuery, import('./api').PricingPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PricingPlanQuery, import('./api').PricingPlanQueryVariables>(Operations.PricingPlan, options);
        }
export type PricingPlanQueryHookResult = ReturnType<typeof usePricingPlanQuery>;
export type PricingPlanLazyQueryHookResult = ReturnType<typeof usePricingPlanLazyQuery>;
export function useBlacklistedProfilesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').BlacklistedProfilesQuery, import('./api').BlacklistedProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').BlacklistedProfilesQuery, import('./api').BlacklistedProfilesQueryVariables>(Operations.BlacklistedProfiles, options);
      }
export function useBlacklistedProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').BlacklistedProfilesQuery, import('./api').BlacklistedProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').BlacklistedProfilesQuery, import('./api').BlacklistedProfilesQueryVariables>(Operations.BlacklistedProfiles, options);
        }
export type BlacklistedProfilesQueryHookResult = ReturnType<typeof useBlacklistedProfilesQuery>;
export type BlacklistedProfilesLazyQueryHookResult = ReturnType<typeof useBlacklistedProfilesLazyQuery>;
export function useBlacklistedProfileQuery(baseOptions: Apollo.QueryHookOptions<import('./api').BlacklistedProfileQuery, import('./api').BlacklistedProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').BlacklistedProfileQuery, import('./api').BlacklistedProfileQueryVariables>(Operations.BlacklistedProfile, options);
      }
export function useBlacklistedProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').BlacklistedProfileQuery, import('./api').BlacklistedProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').BlacklistedProfileQuery, import('./api').BlacklistedProfileQueryVariables>(Operations.BlacklistedProfile, options);
        }
export type BlacklistedProfileQueryHookResult = ReturnType<typeof useBlacklistedProfileQuery>;
export type BlacklistedProfileLazyQueryHookResult = ReturnType<typeof useBlacklistedProfileLazyQuery>;
export function useProfilesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ProfilesQuery, import('./api').ProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ProfilesQuery, import('./api').ProfilesQueryVariables>(Operations.Profiles, options);
      }
export function useProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ProfilesQuery, import('./api').ProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ProfilesQuery, import('./api').ProfilesQueryVariables>(Operations.Profiles, options);
        }
export type ProfilesQueryHookResult = ReturnType<typeof useProfilesQuery>;
export type ProfilesLazyQueryHookResult = ReturnType<typeof useProfilesLazyQuery>;
export function useProfileQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ProfileQuery, import('./api').ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ProfileQuery, import('./api').ProfileQueryVariables>(Operations.Profile, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ProfileQuery, import('./api').ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ProfileQuery, import('./api').ProfileQueryVariables>(Operations.Profile, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export function useProfileRelationsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ProfileRelationsQuery, import('./api').ProfileRelationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ProfileRelationsQuery, import('./api').ProfileRelationsQueryVariables>(Operations.ProfileRelations, options);
      }
export function useProfileRelationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ProfileRelationsQuery, import('./api').ProfileRelationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ProfileRelationsQuery, import('./api').ProfileRelationsQueryVariables>(Operations.ProfileRelations, options);
        }
export type ProfileRelationsQueryHookResult = ReturnType<typeof useProfileRelationsQuery>;
export type ProfileRelationsLazyQueryHookResult = ReturnType<typeof useProfileRelationsLazyQuery>;
export function useProhibitedCountriesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ProhibitedCountriesQuery, import('./api').ProhibitedCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ProhibitedCountriesQuery, import('./api').ProhibitedCountriesQueryVariables>(Operations.ProhibitedCountries, options);
      }
export function useProhibitedCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ProhibitedCountriesQuery, import('./api').ProhibitedCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ProhibitedCountriesQuery, import('./api').ProhibitedCountriesQueryVariables>(Operations.ProhibitedCountries, options);
        }
export type ProhibitedCountriesQueryHookResult = ReturnType<typeof useProhibitedCountriesQuery>;
export type ProhibitedCountriesLazyQueryHookResult = ReturnType<typeof useProhibitedCountriesLazyQuery>;
export function useReminderHistoryQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ReminderHistoryQuery, import('./api').ReminderHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ReminderHistoryQuery, import('./api').ReminderHistoryQueryVariables>(Operations.ReminderHistory, options);
      }
export function useReminderHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ReminderHistoryQuery, import('./api').ReminderHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ReminderHistoryQuery, import('./api').ReminderHistoryQueryVariables>(Operations.ReminderHistory, options);
        }
export type ReminderHistoryQueryHookResult = ReturnType<typeof useReminderHistoryQuery>;
export type ReminderHistoryLazyQueryHookResult = ReturnType<typeof useReminderHistoryLazyQuery>;
export function useCaseRemindersQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseRemindersQuery, import('./api').CaseRemindersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseRemindersQuery, import('./api').CaseRemindersQueryVariables>(Operations.CaseReminders, options);
      }
export function useCaseRemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseRemindersQuery, import('./api').CaseRemindersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseRemindersQuery, import('./api').CaseRemindersQueryVariables>(Operations.CaseReminders, options);
        }
export type CaseRemindersQueryHookResult = ReturnType<typeof useCaseRemindersQuery>;
export type CaseRemindersLazyQueryHookResult = ReturnType<typeof useCaseRemindersLazyQuery>;
export function useCaseReminderSettingsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CaseReminderSettingsQuery, import('./api').CaseReminderSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseReminderSettingsQuery, import('./api').CaseReminderSettingsQueryVariables>(Operations.CaseReminderSettings, options);
      }
export function useCaseReminderSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseReminderSettingsQuery, import('./api').CaseReminderSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseReminderSettingsQuery, import('./api').CaseReminderSettingsQueryVariables>(Operations.CaseReminderSettings, options);
        }
export type CaseReminderSettingsQueryHookResult = ReturnType<typeof useCaseReminderSettingsQuery>;
export type CaseReminderSettingsLazyQueryHookResult = ReturnType<typeof useCaseReminderSettingsLazyQuery>;
export function useCaseReminderKindsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CaseReminderKindsQuery, import('./api').CaseReminderKindsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseReminderKindsQuery, import('./api').CaseReminderKindsQueryVariables>(Operations.CaseReminderKinds, options);
      }
export function useCaseReminderKindsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseReminderKindsQuery, import('./api').CaseReminderKindsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseReminderKindsQuery, import('./api').CaseReminderKindsQueryVariables>(Operations.CaseReminderKinds, options);
        }
export type CaseReminderKindsQueryHookResult = ReturnType<typeof useCaseReminderKindsQuery>;
export type CaseReminderKindsLazyQueryHookResult = ReturnType<typeof useCaseReminderKindsLazyQuery>;
export function useRequiredActionsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').RequiredActionsQuery, import('./api').RequiredActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RequiredActionsQuery, import('./api').RequiredActionsQueryVariables>(Operations.RequiredActions, options);
      }
export function useRequiredActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RequiredActionsQuery, import('./api').RequiredActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RequiredActionsQuery, import('./api').RequiredActionsQueryVariables>(Operations.RequiredActions, options);
        }
export type RequiredActionsQueryHookResult = ReturnType<typeof useRequiredActionsQuery>;
export type RequiredActionsLazyQueryHookResult = ReturnType<typeof useRequiredActionsLazyQuery>;
export function useRequiredActionsSourcesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').RequiredActionsSourcesQuery, import('./api').RequiredActionsSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RequiredActionsSourcesQuery, import('./api').RequiredActionsSourcesQueryVariables>(Operations.RequiredActionsSources, options);
      }
export function useRequiredActionsSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RequiredActionsSourcesQuery, import('./api').RequiredActionsSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RequiredActionsSourcesQuery, import('./api').RequiredActionsSourcesQueryVariables>(Operations.RequiredActionsSources, options);
        }
export type RequiredActionsSourcesQueryHookResult = ReturnType<typeof useRequiredActionsSourcesQuery>;
export type RequiredActionsSourcesLazyQueryHookResult = ReturnType<typeof useRequiredActionsSourcesLazyQuery>;
export function useSarMexicanStatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').SarMexicanStatesQuery, import('./api').SarMexicanStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SarMexicanStatesQuery, import('./api').SarMexicanStatesQueryVariables>(Operations.SarMexicanStates, options);
      }
export function useSarMexicanStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SarMexicanStatesQuery, import('./api').SarMexicanStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SarMexicanStatesQuery, import('./api').SarMexicanStatesQueryVariables>(Operations.SarMexicanStates, options);
        }
export type SarMexicanStatesQueryHookResult = ReturnType<typeof useSarMexicanStatesQuery>;
export type SarMexicanStatesLazyQueryHookResult = ReturnType<typeof useSarMexicanStatesLazyQuery>;
export function useSarUsStatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').SarUsStatesQuery, import('./api').SarUsStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SarUsStatesQuery, import('./api').SarUsStatesQueryVariables>(Operations.SarUsStates, options);
      }
export function useSarUsStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SarUsStatesQuery, import('./api').SarUsStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SarUsStatesQuery, import('./api').SarUsStatesQueryVariables>(Operations.SarUsStates, options);
        }
export type SarUsStatesQueryHookResult = ReturnType<typeof useSarUsStatesQuery>;
export type SarUsStatesLazyQueryHookResult = ReturnType<typeof useSarUsStatesLazyQuery>;
export function useSarCanadianStatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').SarCanadianStatesQuery, import('./api').SarCanadianStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SarCanadianStatesQuery, import('./api').SarCanadianStatesQueryVariables>(Operations.SarCanadianStates, options);
      }
export function useSarCanadianStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SarCanadianStatesQuery, import('./api').SarCanadianStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SarCanadianStatesQuery, import('./api').SarCanadianStatesQueryVariables>(Operations.SarCanadianStates, options);
        }
export type SarCanadianStatesQueryHookResult = ReturnType<typeof useSarCanadianStatesQuery>;
export type SarCanadianStatesLazyQueryHookResult = ReturnType<typeof useSarCanadianStatesLazyQuery>;
export function useSarNaicsCodesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').SarNaicsCodesQuery, import('./api').SarNaicsCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SarNaicsCodesQuery, import('./api').SarNaicsCodesQueryVariables>(Operations.SarNaicsCodes, options);
      }
export function useSarNaicsCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SarNaicsCodesQuery, import('./api').SarNaicsCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SarNaicsCodesQuery, import('./api').SarNaicsCodesQueryVariables>(Operations.SarNaicsCodes, options);
        }
export type SarNaicsCodesQueryHookResult = ReturnType<typeof useSarNaicsCodesQuery>;
export type SarNaicsCodesLazyQueryHookResult = ReturnType<typeof useSarNaicsCodesLazyQuery>;
export function useSarReportsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').SarReportsQuery, import('./api').SarReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SarReportsQuery, import('./api').SarReportsQueryVariables>(Operations.SarReports, options);
      }
export function useSarReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SarReportsQuery, import('./api').SarReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SarReportsQuery, import('./api').SarReportsQueryVariables>(Operations.SarReports, options);
        }
export type SarReportsQueryHookResult = ReturnType<typeof useSarReportsQuery>;
export type SarReportsLazyQueryHookResult = ReturnType<typeof useSarReportsLazyQuery>;
export function useSarReportQuery(baseOptions: Apollo.QueryHookOptions<import('./api').SarReportQuery, import('./api').SarReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SarReportQuery, import('./api').SarReportQueryVariables>(Operations.SarReport, options);
      }
export function useSarReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SarReportQuery, import('./api').SarReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SarReportQuery, import('./api').SarReportQueryVariables>(Operations.SarReport, options);
        }
export type SarReportQueryHookResult = ReturnType<typeof useSarReportQuery>;
export type SarReportLazyQueryHookResult = ReturnType<typeof useSarReportLazyQuery>;
export function useSarStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').SarStatsQuery, import('./api').SarStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SarStatsQuery, import('./api').SarStatsQueryVariables>(Operations.SarStats, options);
      }
export function useSarStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SarStatsQuery, import('./api').SarStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SarStatsQuery, import('./api').SarStatsQueryVariables>(Operations.SarStats, options);
        }
export type SarStatsQueryHookResult = ReturnType<typeof useSarStatsQuery>;
export type SarStatsLazyQueryHookResult = ReturnType<typeof useSarStatsLazyQuery>;
export function useCaseScoringQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseScoringQuery, import('./api').CaseScoringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseScoringQuery, import('./api').CaseScoringQueryVariables>(Operations.CaseScoring, options);
      }
export function useCaseScoringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseScoringQuery, import('./api').CaseScoringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseScoringQuery, import('./api').CaseScoringQueryVariables>(Operations.CaseScoring, options);
        }
export type CaseScoringQueryHookResult = ReturnType<typeof useCaseScoringQuery>;
export type CaseScoringLazyQueryHookResult = ReturnType<typeof useCaseScoringLazyQuery>;
export function useCaseScoringModelQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CaseScoringModelQuery, import('./api').CaseScoringModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CaseScoringModelQuery, import('./api').CaseScoringModelQueryVariables>(Operations.CaseScoringModel, options);
      }
export function useCaseScoringModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CaseScoringModelQuery, import('./api').CaseScoringModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CaseScoringModelQuery, import('./api').CaseScoringModelQueryVariables>(Operations.CaseScoringModel, options);
        }
export type CaseScoringModelQueryHookResult = ReturnType<typeof useCaseScoringModelQuery>;
export type CaseScoringModelLazyQueryHookResult = ReturnType<typeof useCaseScoringModelLazyQuery>;
export function useFormScoringModelQuery(baseOptions: Apollo.QueryHookOptions<import('./api').FormScoringModelQuery, import('./api').FormScoringModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FormScoringModelQuery, import('./api').FormScoringModelQueryVariables>(Operations.FormScoringModel, options);
      }
export function useFormScoringModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FormScoringModelQuery, import('./api').FormScoringModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FormScoringModelQuery, import('./api').FormScoringModelQueryVariables>(Operations.FormScoringModel, options);
        }
export type FormScoringModelQueryHookResult = ReturnType<typeof useFormScoringModelQuery>;
export type FormScoringModelLazyQueryHookResult = ReturnType<typeof useFormScoringModelLazyQuery>;
export function useFieldScoringQuery(baseOptions: Apollo.QueryHookOptions<import('./api').FieldScoringQuery, import('./api').FieldScoringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').FieldScoringQuery, import('./api').FieldScoringQueryVariables>(Operations.FieldScoring, options);
      }
export function useFieldScoringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').FieldScoringQuery, import('./api').FieldScoringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').FieldScoringQuery, import('./api').FieldScoringQueryVariables>(Operations.FieldScoring, options);
        }
export type FieldScoringQueryHookResult = ReturnType<typeof useFieldScoringQuery>;
export type FieldScoringLazyQueryHookResult = ReturnType<typeof useFieldScoringLazyQuery>;
export function useSubmissionScoringQuery(baseOptions: Apollo.QueryHookOptions<import('./api').SubmissionScoringQuery, import('./api').SubmissionScoringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SubmissionScoringQuery, import('./api').SubmissionScoringQueryVariables>(Operations.SubmissionScoring, options);
      }
export function useSubmissionScoringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SubmissionScoringQuery, import('./api').SubmissionScoringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SubmissionScoringQuery, import('./api').SubmissionScoringQueryVariables>(Operations.SubmissionScoring, options);
        }
export type SubmissionScoringQueryHookResult = ReturnType<typeof useSubmissionScoringQuery>;
export type SubmissionScoringLazyQueryHookResult = ReturnType<typeof useSubmissionScoringLazyQuery>;
export function useApprovalIndividualQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ApprovalIndividualQuery, import('./api').ApprovalIndividualQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ApprovalIndividualQuery, import('./api').ApprovalIndividualQueryVariables>(Operations.ApprovalIndividual, options);
      }
export function useApprovalIndividualLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ApprovalIndividualQuery, import('./api').ApprovalIndividualQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ApprovalIndividualQuery, import('./api').ApprovalIndividualQueryVariables>(Operations.ApprovalIndividual, options);
        }
export type ApprovalIndividualQueryHookResult = ReturnType<typeof useApprovalIndividualQuery>;
export type ApprovalIndividualLazyQueryHookResult = ReturnType<typeof useApprovalIndividualLazyQuery>;
export function useApprovalGeneralQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ApprovalGeneralQuery, import('./api').ApprovalGeneralQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ApprovalGeneralQuery, import('./api').ApprovalGeneralQueryVariables>(Operations.ApprovalGeneral, options);
      }
export function useApprovalGeneralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ApprovalGeneralQuery, import('./api').ApprovalGeneralQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ApprovalGeneralQuery, import('./api').ApprovalGeneralQueryVariables>(Operations.ApprovalGeneral, options);
        }
export type ApprovalGeneralQueryHookResult = ReturnType<typeof useApprovalGeneralQuery>;
export type ApprovalGeneralLazyQueryHookResult = ReturnType<typeof useApprovalGeneralLazyQuery>;
export function useAvgAssignmentQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AvgAssignmentQuery, import('./api').AvgAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AvgAssignmentQuery, import('./api').AvgAssignmentQueryVariables>(Operations.AvgAssignment, options);
      }
export function useAvgAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AvgAssignmentQuery, import('./api').AvgAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AvgAssignmentQuery, import('./api').AvgAssignmentQueryVariables>(Operations.AvgAssignment, options);
        }
export type AvgAssignmentQueryHookResult = ReturnType<typeof useAvgAssignmentQuery>;
export type AvgAssignmentLazyQueryHookResult = ReturnType<typeof useAvgAssignmentLazyQuery>;
export function useAvgProcessingQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AvgProcessingQuery, import('./api').AvgProcessingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AvgProcessingQuery, import('./api').AvgProcessingQueryVariables>(Operations.AvgProcessing, options);
      }
export function useAvgProcessingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AvgProcessingQuery, import('./api').AvgProcessingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AvgProcessingQuery, import('./api').AvgProcessingQueryVariables>(Operations.AvgProcessing, options);
        }
export type AvgProcessingQueryHookResult = ReturnType<typeof useAvgProcessingQuery>;
export type AvgProcessingLazyQueryHookResult = ReturnType<typeof useAvgProcessingLazyQuery>;
export function useNewCasesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').NewCasesQuery, import('./api').NewCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').NewCasesQuery, import('./api').NewCasesQueryVariables>(Operations.NewCases, options);
      }
export function useNewCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').NewCasesQuery, import('./api').NewCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').NewCasesQuery, import('./api').NewCasesQueryVariables>(Operations.NewCases, options);
        }
export type NewCasesQueryHookResult = ReturnType<typeof useNewCasesQuery>;
export type NewCasesLazyQueryHookResult = ReturnType<typeof useNewCasesLazyQuery>;
export function useCasesNewRealStatsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CasesNewRealStatsQuery, import('./api').CasesNewRealStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CasesNewRealStatsQuery, import('./api').CasesNewRealStatsQueryVariables>(Operations.CasesNewRealStats, options);
      }
export function useCasesNewRealStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CasesNewRealStatsQuery, import('./api').CasesNewRealStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CasesNewRealStatsQuery, import('./api').CasesNewRealStatsQueryVariables>(Operations.CasesNewRealStats, options);
        }
export type CasesNewRealStatsQueryHookResult = ReturnType<typeof useCasesNewRealStatsQuery>;
export type CasesNewRealStatsLazyQueryHookResult = ReturnType<typeof useCasesNewRealStatsLazyQuery>;
export function useCasesStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CasesStatsQuery, import('./api').CasesStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CasesStatsQuery, import('./api').CasesStatsQueryVariables>(Operations.CasesStats, options);
      }
export function useCasesStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CasesStatsQuery, import('./api').CasesStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CasesStatsQuery, import('./api').CasesStatsQueryVariables>(Operations.CasesStats, options);
        }
export type CasesStatsQueryHookResult = ReturnType<typeof useCasesStatsQuery>;
export type CasesStatsLazyQueryHookResult = ReturnType<typeof useCasesStatsLazyQuery>;
export function useGetStateStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').GetStateStatsQuery, import('./api').GetStateStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').GetStateStatsQuery, import('./api').GetStateStatsQueryVariables>(Operations.GetStateStats, options);
      }
export function useGetStateStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').GetStateStatsQuery, import('./api').GetStateStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').GetStateStatsQuery, import('./api').GetStateStatsQueryVariables>(Operations.GetStateStats, options);
        }
export type GetStateStatsQueryHookResult = ReturnType<typeof useGetStateStatsQuery>;
export type GetStateStatsLazyQueryHookResult = ReturnType<typeof useGetStateStatsLazyQuery>;
export function useGetRegionStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').GetRegionStatsQuery, import('./api').GetRegionStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').GetRegionStatsQuery, import('./api').GetRegionStatsQueryVariables>(Operations.GetRegionStats, options);
      }
export function useGetRegionStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').GetRegionStatsQuery, import('./api').GetRegionStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').GetRegionStatsQuery, import('./api').GetRegionStatsQueryVariables>(Operations.GetRegionStats, options);
        }
export type GetRegionStatsQueryHookResult = ReturnType<typeof useGetRegionStatsQuery>;
export type GetRegionStatsLazyQueryHookResult = ReturnType<typeof useGetRegionStatsLazyQuery>;
export function useGetRejectReasonStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').GetRejectReasonStatsQuery, import('./api').GetRejectReasonStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').GetRejectReasonStatsQuery, import('./api').GetRejectReasonStatsQueryVariables>(Operations.GetRejectReasonStats, options);
      }
export function useGetRejectReasonStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').GetRejectReasonStatsQuery, import('./api').GetRejectReasonStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').GetRejectReasonStatsQuery, import('./api').GetRejectReasonStatsQueryVariables>(Operations.GetRejectReasonStats, options);
        }
export type GetRejectReasonStatsQueryHookResult = ReturnType<typeof useGetRejectReasonStatsQuery>;
export type GetRejectReasonStatsLazyQueryHookResult = ReturnType<typeof useGetRejectReasonStatsLazyQuery>;
export function useCheckupStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CheckupStatsQuery, import('./api').CheckupStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CheckupStatsQuery, import('./api').CheckupStatsQueryVariables>(Operations.CheckupStats, options);
      }
export function useCheckupStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CheckupStatsQuery, import('./api').CheckupStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CheckupStatsQuery, import('./api').CheckupStatsQueryVariables>(Operations.CheckupStats, options);
        }
export type CheckupStatsQueryHookResult = ReturnType<typeof useCheckupStatsQuery>;
export type CheckupStatsLazyQueryHookResult = ReturnType<typeof useCheckupStatsLazyQuery>;
export function useEddReportsStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').EddReportsStatsQuery, import('./api').EddReportsStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').EddReportsStatsQuery, import('./api').EddReportsStatsQueryVariables>(Operations.EDDReportsStats, options);
      }
export function useEddReportsStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').EddReportsStatsQuery, import('./api').EddReportsStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').EddReportsStatsQuery, import('./api').EddReportsStatsQueryVariables>(Operations.EDDReportsStats, options);
        }
export type EddReportsStatsQueryHookResult = ReturnType<typeof useEddReportsStatsQuery>;
export type EddReportsStatsLazyQueryHookResult = ReturnType<typeof useEddReportsStatsLazyQuery>;
export function usePpFincenListsStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').PpFincenListsStatsQuery, import('./api').PpFincenListsStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').PpFincenListsStatsQuery, import('./api').PpFincenListsStatsQueryVariables>(Operations.PPFincenListsStats, options);
      }
export function usePpFincenListsStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').PpFincenListsStatsQuery, import('./api').PpFincenListsStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').PpFincenListsStatsQuery, import('./api').PpFincenListsStatsQueryVariables>(Operations.PPFincenListsStats, options);
        }
export type PpFincenListsStatsQueryHookResult = ReturnType<typeof usePpFincenListsStatsQuery>;
export type PpFincenListsStatsLazyQueryHookResult = ReturnType<typeof usePpFincenListsStatsLazyQuery>;
export function useComplienceRejectReasonsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').ComplienceRejectReasonsQuery, import('./api').ComplienceRejectReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ComplienceRejectReasonsQuery, import('./api').ComplienceRejectReasonsQueryVariables>(Operations.ComplienceRejectReasons, options);
      }
export function useComplienceRejectReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ComplienceRejectReasonsQuery, import('./api').ComplienceRejectReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ComplienceRejectReasonsQuery, import('./api').ComplienceRejectReasonsQueryVariables>(Operations.ComplienceRejectReasons, options);
        }
export type ComplienceRejectReasonsQueryHookResult = ReturnType<typeof useComplienceRejectReasonsQuery>;
export type ComplienceRejectReasonsLazyQueryHookResult = ReturnType<typeof useComplienceRejectReasonsLazyQuery>;
export function useRfiIndividualQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').RfiIndividualQuery, import('./api').RfiIndividualQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RfiIndividualQuery, import('./api').RfiIndividualQueryVariables>(Operations.RfiIndividual, options);
      }
export function useRfiIndividualLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RfiIndividualQuery, import('./api').RfiIndividualQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RfiIndividualQuery, import('./api').RfiIndividualQueryVariables>(Operations.RfiIndividual, options);
        }
export type RfiIndividualQueryHookResult = ReturnType<typeof useRfiIndividualQuery>;
export type RfiIndividualLazyQueryHookResult = ReturnType<typeof useRfiIndividualLazyQuery>;
export function useRfiGeneralQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').RfiGeneralQuery, import('./api').RfiGeneralQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RfiGeneralQuery, import('./api').RfiGeneralQueryVariables>(Operations.RfiGeneral, options);
      }
export function useRfiGeneralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RfiGeneralQuery, import('./api').RfiGeneralQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RfiGeneralQuery, import('./api').RfiGeneralQueryVariables>(Operations.RfiGeneral, options);
        }
export type RfiGeneralQueryHookResult = ReturnType<typeof useRfiGeneralQuery>;
export type RfiGeneralLazyQueryHookResult = ReturnType<typeof useRfiGeneralLazyQuery>;
export function useSlaGeneralQuery(baseOptions: Apollo.QueryHookOptions<import('./api').SlaGeneralQuery, import('./api').SlaGeneralQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SlaGeneralQuery, import('./api').SlaGeneralQueryVariables>(Operations.SlaGeneral, options);
      }
export function useSlaGeneralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SlaGeneralQuery, import('./api').SlaGeneralQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SlaGeneralQuery, import('./api').SlaGeneralQueryVariables>(Operations.SlaGeneral, options);
        }
export type SlaGeneralQueryHookResult = ReturnType<typeof useSlaGeneralQuery>;
export type SlaGeneralLazyQueryHookResult = ReturnType<typeof useSlaGeneralLazyQuery>;
export function useSlaPeriodCommonQuery(baseOptions: Apollo.QueryHookOptions<import('./api').SlaPeriodCommonQuery, import('./api').SlaPeriodCommonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SlaPeriodCommonQuery, import('./api').SlaPeriodCommonQueryVariables>(Operations.SlaPeriodCommon, options);
      }
export function useSlaPeriodCommonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SlaPeriodCommonQuery, import('./api').SlaPeriodCommonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SlaPeriodCommonQuery, import('./api').SlaPeriodCommonQueryVariables>(Operations.SlaPeriodCommon, options);
        }
export type SlaPeriodCommonQueryHookResult = ReturnType<typeof useSlaPeriodCommonQuery>;
export type SlaPeriodCommonLazyQueryHookResult = ReturnType<typeof useSlaPeriodCommonLazyQuery>;
export function useSlaPeriodPersonalQuery(baseOptions: Apollo.QueryHookOptions<import('./api').SlaPeriodPersonalQuery, import('./api').SlaPeriodPersonalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SlaPeriodPersonalQuery, import('./api').SlaPeriodPersonalQueryVariables>(Operations.SlaPeriodPersonal, options);
      }
export function useSlaPeriodPersonalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SlaPeriodPersonalQuery, import('./api').SlaPeriodPersonalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SlaPeriodPersonalQuery, import('./api').SlaPeriodPersonalQueryVariables>(Operations.SlaPeriodPersonal, options);
        }
export type SlaPeriodPersonalQueryHookResult = ReturnType<typeof useSlaPeriodPersonalQuery>;
export type SlaPeriodPersonalLazyQueryHookResult = ReturnType<typeof useSlaPeriodPersonalLazyQuery>;
export function useSlaRfiPersonalQuery(baseOptions: Apollo.QueryHookOptions<import('./api').SlaRfiPersonalQuery, import('./api').SlaRfiPersonalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SlaRfiPersonalQuery, import('./api').SlaRfiPersonalQueryVariables>(Operations.SlaRfiPersonal, options);
      }
export function useSlaRfiPersonalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SlaRfiPersonalQuery, import('./api').SlaRfiPersonalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SlaRfiPersonalQuery, import('./api').SlaRfiPersonalQueryVariables>(Operations.SlaRfiPersonal, options);
        }
export type SlaRfiPersonalQueryHookResult = ReturnType<typeof useSlaRfiPersonalQuery>;
export type SlaRfiPersonalLazyQueryHookResult = ReturnType<typeof useSlaRfiPersonalLazyQuery>;
export function useSubmissionsStatsPresetsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').SubmissionsStatsPresetsQuery, import('./api').SubmissionsStatsPresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SubmissionsStatsPresetsQuery, import('./api').SubmissionsStatsPresetsQueryVariables>(Operations.SubmissionsStatsPresets, options);
      }
export function useSubmissionsStatsPresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SubmissionsStatsPresetsQuery, import('./api').SubmissionsStatsPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SubmissionsStatsPresetsQuery, import('./api').SubmissionsStatsPresetsQueryVariables>(Operations.SubmissionsStatsPresets, options);
        }
export type SubmissionsStatsPresetsQueryHookResult = ReturnType<typeof useSubmissionsStatsPresetsQuery>;
export type SubmissionsStatsPresetsLazyQueryHookResult = ReturnType<typeof useSubmissionsStatsPresetsLazyQuery>;
export function useSubmissionsStatsPresetQuery(baseOptions: Apollo.QueryHookOptions<import('./api').SubmissionsStatsPresetQuery, import('./api').SubmissionsStatsPresetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SubmissionsStatsPresetQuery, import('./api').SubmissionsStatsPresetQueryVariables>(Operations.SubmissionsStatsPreset, options);
      }
export function useSubmissionsStatsPresetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SubmissionsStatsPresetQuery, import('./api').SubmissionsStatsPresetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SubmissionsStatsPresetQuery, import('./api').SubmissionsStatsPresetQueryVariables>(Operations.SubmissionsStatsPreset, options);
        }
export type SubmissionsStatsPresetQueryHookResult = ReturnType<typeof useSubmissionsStatsPresetQuery>;
export type SubmissionsStatsPresetLazyQueryHookResult = ReturnType<typeof useSubmissionsStatsPresetLazyQuery>;
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TagsQuery, import('./api').TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TagsQuery, import('./api').TagsQueryVariables>(Operations.Tags, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TagsQuery, import('./api').TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TagsQuery, import('./api').TagsQueryVariables>(Operations.Tags, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export function useTariffNamesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TariffNamesQuery, import('./api').TariffNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TariffNamesQuery, import('./api').TariffNamesQueryVariables>(Operations.TariffNames, options);
      }
export function useTariffNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TariffNamesQuery, import('./api').TariffNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TariffNamesQuery, import('./api').TariffNamesQueryVariables>(Operations.TariffNames, options);
        }
export type TariffNamesQueryHookResult = ReturnType<typeof useTariffNamesQuery>;
export type TariffNamesLazyQueryHookResult = ReturnType<typeof useTariffNamesLazyQuery>;
export function useAlertsStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').AlertsStatsQuery, import('./api').AlertsStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').AlertsStatsQuery, import('./api').AlertsStatsQueryVariables>(Operations.AlertsStats, options);
      }
export function useAlertsStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').AlertsStatsQuery, import('./api').AlertsStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').AlertsStatsQuery, import('./api').AlertsStatsQueryVariables>(Operations.AlertsStats, options);
        }
export type AlertsStatsQueryHookResult = ReturnType<typeof useAlertsStatsQuery>;
export type AlertsStatsLazyQueryHookResult = ReturnType<typeof useAlertsStatsLazyQuery>;
export function useQueuedAlertsStatsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').QueuedAlertsStatsQuery, import('./api').QueuedAlertsStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').QueuedAlertsStatsQuery, import('./api').QueuedAlertsStatsQueryVariables>(Operations.QueuedAlertsStats, options);
      }
export function useQueuedAlertsStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').QueuedAlertsStatsQuery, import('./api').QueuedAlertsStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').QueuedAlertsStatsQuery, import('./api').QueuedAlertsStatsQueryVariables>(Operations.QueuedAlertsStats, options);
        }
export type QueuedAlertsStatsQueryHookResult = ReturnType<typeof useQueuedAlertsStatsQuery>;
export type QueuedAlertsStatsLazyQueryHookResult = ReturnType<typeof useQueuedAlertsStatsLazyQuery>;
export function useSubmissionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').SubmissionsQuery, import('./api').SubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SubmissionsQuery, import('./api').SubmissionsQueryVariables>(Operations.Submissions, options);
      }
export function useSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SubmissionsQuery, import('./api').SubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SubmissionsQuery, import('./api').SubmissionsQueryVariables>(Operations.Submissions, options);
        }
export type SubmissionsQueryHookResult = ReturnType<typeof useSubmissionsQuery>;
export type SubmissionsLazyQueryHookResult = ReturnType<typeof useSubmissionsLazyQuery>;
export function useRevisionsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').RevisionsQuery, import('./api').RevisionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').RevisionsQuery, import('./api').RevisionsQueryVariables>(Operations.Revisions, options);
      }
export function useRevisionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').RevisionsQuery, import('./api').RevisionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').RevisionsQuery, import('./api').RevisionsQueryVariables>(Operations.Revisions, options);
        }
export type RevisionsQueryHookResult = ReturnType<typeof useRevisionsQuery>;
export type RevisionsLazyQueryHookResult = ReturnType<typeof useRevisionsLazyQuery>;
export function useSubmissionQuery(baseOptions: Apollo.QueryHookOptions<import('./api').SubmissionQuery, import('./api').SubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SubmissionQuery, import('./api').SubmissionQueryVariables>(Operations.Submission, options);
      }
export function useSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SubmissionQuery, import('./api').SubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SubmissionQuery, import('./api').SubmissionQueryVariables>(Operations.Submission, options);
        }
export type SubmissionQueryHookResult = ReturnType<typeof useSubmissionQuery>;
export type SubmissionLazyQueryHookResult = ReturnType<typeof useSubmissionLazyQuery>;
export function useSubmissionUrlQuery(baseOptions: Apollo.QueryHookOptions<import('./api').SubmissionUrlQuery, import('./api').SubmissionUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SubmissionUrlQuery, import('./api').SubmissionUrlQueryVariables>(Operations.SubmissionUrl, options);
      }
export function useSubmissionUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SubmissionUrlQuery, import('./api').SubmissionUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SubmissionUrlQuery, import('./api').SubmissionUrlQueryVariables>(Operations.SubmissionUrl, options);
        }
export type SubmissionUrlQueryHookResult = ReturnType<typeof useSubmissionUrlQuery>;
export type SubmissionUrlLazyQueryHookResult = ReturnType<typeof useSubmissionUrlLazyQuery>;
export function useSubmissionReportQuery(baseOptions: Apollo.QueryHookOptions<import('./api').SubmissionReportQuery, import('./api').SubmissionReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SubmissionReportQuery, import('./api').SubmissionReportQueryVariables>(Operations.SubmissionReport, options);
      }
export function useSubmissionReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SubmissionReportQuery, import('./api').SubmissionReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SubmissionReportQuery, import('./api').SubmissionReportQueryVariables>(Operations.SubmissionReport, options);
        }
export type SubmissionReportQueryHookResult = ReturnType<typeof useSubmissionReportQuery>;
export type SubmissionReportLazyQueryHookResult = ReturnType<typeof useSubmissionReportLazyQuery>;
export function useSubmissionsReportQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').SubmissionsReportQuery, import('./api').SubmissionsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').SubmissionsReportQuery, import('./api').SubmissionsReportQueryVariables>(Operations.SubmissionsReport, options);
      }
export function useSubmissionsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').SubmissionsReportQuery, import('./api').SubmissionsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').SubmissionsReportQuery, import('./api').SubmissionsReportQueryVariables>(Operations.SubmissionsReport, options);
        }
export type SubmissionsReportQueryHookResult = ReturnType<typeof useSubmissionsReportQuery>;
export type SubmissionsReportLazyQueryHookResult = ReturnType<typeof useSubmissionsReportLazyQuery>;
export function useTabRegistriesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TabRegistriesQuery, import('./api').TabRegistriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TabRegistriesQuery, import('./api').TabRegistriesQueryVariables>(Operations.TabRegistries, options);
      }
export function useTabRegistriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TabRegistriesQuery, import('./api').TabRegistriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TabRegistriesQuery, import('./api').TabRegistriesQueryVariables>(Operations.TabRegistries, options);
        }
export type TabRegistriesQueryHookResult = ReturnType<typeof useTabRegistriesQuery>;
export type TabRegistriesLazyQueryHookResult = ReturnType<typeof useTabRegistriesLazyQuery>;
export function useTransactionAccountsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionAccountsQuery, import('./api').TransactionAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionAccountsQuery, import('./api').TransactionAccountsQueryVariables>(Operations.TransactionAccounts, options);
      }
export function useTransactionAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionAccountsQuery, import('./api').TransactionAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionAccountsQuery, import('./api').TransactionAccountsQueryVariables>(Operations.TransactionAccounts, options);
        }
export type TransactionAccountsQueryHookResult = ReturnType<typeof useTransactionAccountsQuery>;
export type TransactionAccountsLazyQueryHookResult = ReturnType<typeof useTransactionAccountsLazyQuery>;
export function useTransactionAccountQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionAccountQuery, import('./api').TransactionAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionAccountQuery, import('./api').TransactionAccountQueryVariables>(Operations.TransactionAccount, options);
      }
export function useTransactionAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionAccountQuery, import('./api').TransactionAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionAccountQuery, import('./api').TransactionAccountQueryVariables>(Operations.TransactionAccount, options);
        }
export type TransactionAccountQueryHookResult = ReturnType<typeof useTransactionAccountQuery>;
export type TransactionAccountLazyQueryHookResult = ReturnType<typeof useTransactionAccountLazyQuery>;
export function useTransactionActivityTypesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionActivityTypesQuery, import('./api').TransactionActivityTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionActivityTypesQuery, import('./api').TransactionActivityTypesQueryVariables>(Operations.TransactionActivityTypes, options);
      }
export function useTransactionActivityTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionActivityTypesQuery, import('./api').TransactionActivityTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionActivityTypesQuery, import('./api').TransactionActivityTypesQueryVariables>(Operations.TransactionActivityTypes, options);
        }
export type TransactionActivityTypesQueryHookResult = ReturnType<typeof useTransactionActivityTypesQuery>;
export type TransactionActivityTypesLazyQueryHookResult = ReturnType<typeof useTransactionActivityTypesLazyQuery>;
export function useTransactionAlertsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionAlertsQuery, import('./api').TransactionAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionAlertsQuery, import('./api').TransactionAlertsQueryVariables>(Operations.TransactionAlerts, options);
      }
export function useTransactionAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionAlertsQuery, import('./api').TransactionAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionAlertsQuery, import('./api').TransactionAlertsQueryVariables>(Operations.TransactionAlerts, options);
        }
export type TransactionAlertsQueryHookResult = ReturnType<typeof useTransactionAlertsQuery>;
export type TransactionAlertsLazyQueryHookResult = ReturnType<typeof useTransactionAlertsLazyQuery>;
export function useTransactionAlertsQueuedQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionAlertsQueuedQuery, import('./api').TransactionAlertsQueuedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionAlertsQueuedQuery, import('./api').TransactionAlertsQueuedQueryVariables>(Operations.TransactionAlertsQueued, options);
      }
export function useTransactionAlertsQueuedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionAlertsQueuedQuery, import('./api').TransactionAlertsQueuedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionAlertsQueuedQuery, import('./api').TransactionAlertsQueuedQueryVariables>(Operations.TransactionAlertsQueued, options);
        }
export type TransactionAlertsQueuedQueryHookResult = ReturnType<typeof useTransactionAlertsQueuedQuery>;
export type TransactionAlertsQueuedLazyQueryHookResult = ReturnType<typeof useTransactionAlertsQueuedLazyQuery>;
export function useTransactionPriorAlertsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionPriorAlertsQuery, import('./api').TransactionPriorAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionPriorAlertsQuery, import('./api').TransactionPriorAlertsQueryVariables>(Operations.TransactionPriorAlerts, options);
      }
export function useTransactionPriorAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionPriorAlertsQuery, import('./api').TransactionPriorAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionPriorAlertsQuery, import('./api').TransactionPriorAlertsQueryVariables>(Operations.TransactionPriorAlerts, options);
        }
export type TransactionPriorAlertsQueryHookResult = ReturnType<typeof useTransactionPriorAlertsQuery>;
export type TransactionPriorAlertsLazyQueryHookResult = ReturnType<typeof useTransactionPriorAlertsLazyQuery>;
export function useTransactionPriorCasesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionPriorCasesQuery, import('./api').TransactionPriorCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionPriorCasesQuery, import('./api').TransactionPriorCasesQueryVariables>(Operations.TransactionPriorCases, options);
      }
export function useTransactionPriorCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionPriorCasesQuery, import('./api').TransactionPriorCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionPriorCasesQuery, import('./api').TransactionPriorCasesQueryVariables>(Operations.TransactionPriorCases, options);
        }
export type TransactionPriorCasesQueryHookResult = ReturnType<typeof useTransactionPriorCasesQuery>;
export type TransactionPriorCasesLazyQueryHookResult = ReturnType<typeof useTransactionPriorCasesLazyQuery>;
export function useTransactionAlertQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionAlertQuery, import('./api').TransactionAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionAlertQuery, import('./api').TransactionAlertQueryVariables>(Operations.TransactionAlert, options);
      }
export function useTransactionAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionAlertQuery, import('./api').TransactionAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionAlertQuery, import('./api').TransactionAlertQueryVariables>(Operations.TransactionAlert, options);
        }
export type TransactionAlertQueryHookResult = ReturnType<typeof useTransactionAlertQuery>;
export type TransactionAlertLazyQueryHookResult = ReturnType<typeof useTransactionAlertLazyQuery>;
export function useTransactionBlacklistGroupsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionBlacklistGroupsQuery, import('./api').TransactionBlacklistGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionBlacklistGroupsQuery, import('./api').TransactionBlacklistGroupsQueryVariables>(Operations.TransactionBlacklistGroups, options);
      }
export function useTransactionBlacklistGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionBlacklistGroupsQuery, import('./api').TransactionBlacklistGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionBlacklistGroupsQuery, import('./api').TransactionBlacklistGroupsQueryVariables>(Operations.TransactionBlacklistGroups, options);
        }
export type TransactionBlacklistGroupsQueryHookResult = ReturnType<typeof useTransactionBlacklistGroupsQuery>;
export type TransactionBlacklistGroupsLazyQueryHookResult = ReturnType<typeof useTransactionBlacklistGroupsLazyQuery>;
export function useTransactionBlacklistGroupQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionBlacklistGroupQuery, import('./api').TransactionBlacklistGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionBlacklistGroupQuery, import('./api').TransactionBlacklistGroupQueryVariables>(Operations.TransactionBlacklistGroup, options);
      }
export function useTransactionBlacklistGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionBlacklistGroupQuery, import('./api').TransactionBlacklistGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionBlacklistGroupQuery, import('./api').TransactionBlacklistGroupQueryVariables>(Operations.TransactionBlacklistGroup, options);
        }
export type TransactionBlacklistGroupQueryHookResult = ReturnType<typeof useTransactionBlacklistGroupQuery>;
export type TransactionBlacklistGroupLazyQueryHookResult = ReturnType<typeof useTransactionBlacklistGroupLazyQuery>;
export function useTransactionBlacklistCommonItemsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionBlacklistCommonItemsQuery, import('./api').TransactionBlacklistCommonItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionBlacklistCommonItemsQuery, import('./api').TransactionBlacklistCommonItemsQueryVariables>(Operations.TransactionBlacklistCommonItems, options);
      }
export function useTransactionBlacklistCommonItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionBlacklistCommonItemsQuery, import('./api').TransactionBlacklistCommonItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionBlacklistCommonItemsQuery, import('./api').TransactionBlacklistCommonItemsQueryVariables>(Operations.TransactionBlacklistCommonItems, options);
        }
export type TransactionBlacklistCommonItemsQueryHookResult = ReturnType<typeof useTransactionBlacklistCommonItemsQuery>;
export type TransactionBlacklistCommonItemsLazyQueryHookResult = ReturnType<typeof useTransactionBlacklistCommonItemsLazyQuery>;
export function useTransactionCaseOriginsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionCaseOriginsQuery, import('./api').TransactionCaseOriginsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionCaseOriginsQuery, import('./api').TransactionCaseOriginsQueryVariables>(Operations.TransactionCaseOrigins, options);
      }
export function useTransactionCaseOriginsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionCaseOriginsQuery, import('./api').TransactionCaseOriginsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionCaseOriginsQuery, import('./api').TransactionCaseOriginsQueryVariables>(Operations.TransactionCaseOrigins, options);
        }
export type TransactionCaseOriginsQueryHookResult = ReturnType<typeof useTransactionCaseOriginsQuery>;
export type TransactionCaseOriginsLazyQueryHookResult = ReturnType<typeof useTransactionCaseOriginsLazyQuery>;
export function useTransactionCasesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionCasesQuery, import('./api').TransactionCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionCasesQuery, import('./api').TransactionCasesQueryVariables>(Operations.TransactionCases, options);
      }
export function useTransactionCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionCasesQuery, import('./api').TransactionCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionCasesQuery, import('./api').TransactionCasesQueryVariables>(Operations.TransactionCases, options);
        }
export type TransactionCasesQueryHookResult = ReturnType<typeof useTransactionCasesQuery>;
export type TransactionCasesLazyQueryHookResult = ReturnType<typeof useTransactionCasesLazyQuery>;
export function useTransactionCasesQueuedQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionCasesQueuedQuery, import('./api').TransactionCasesQueuedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionCasesQueuedQuery, import('./api').TransactionCasesQueuedQueryVariables>(Operations.TransactionCasesQueued, options);
      }
export function useTransactionCasesQueuedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionCasesQueuedQuery, import('./api').TransactionCasesQueuedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionCasesQueuedQuery, import('./api').TransactionCasesQueuedQueryVariables>(Operations.TransactionCasesQueued, options);
        }
export type TransactionCasesQueuedQueryHookResult = ReturnType<typeof useTransactionCasesQueuedQuery>;
export type TransactionCasesQueuedLazyQueryHookResult = ReturnType<typeof useTransactionCasesQueuedLazyQuery>;
export function useTransactionCaseQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionCaseQuery, import('./api').TransactionCaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionCaseQuery, import('./api').TransactionCaseQueryVariables>(Operations.TransactionCase, options);
      }
export function useTransactionCaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionCaseQuery, import('./api').TransactionCaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionCaseQuery, import('./api').TransactionCaseQueryVariables>(Operations.TransactionCase, options);
        }
export type TransactionCaseQueryHookResult = ReturnType<typeof useTransactionCaseQuery>;
export type TransactionCaseLazyQueryHookResult = ReturnType<typeof useTransactionCaseLazyQuery>;
export function useTransactionCaseVerionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionCaseVerionsQuery, import('./api').TransactionCaseVerionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionCaseVerionsQuery, import('./api').TransactionCaseVerionsQueryVariables>(Operations.TransactionCaseVerions, options);
      }
export function useTransactionCaseVerionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionCaseVerionsQuery, import('./api').TransactionCaseVerionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionCaseVerionsQuery, import('./api').TransactionCaseVerionsQueryVariables>(Operations.TransactionCaseVerions, options);
        }
export type TransactionCaseVerionsQueryHookResult = ReturnType<typeof useTransactionCaseVerionsQuery>;
export type TransactionCaseVerionsLazyQueryHookResult = ReturnType<typeof useTransactionCaseVerionsLazyQuery>;
export function useTransactionCaseExternalRefsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionCaseExternalRefsQuery, import('./api').TransactionCaseExternalRefsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionCaseExternalRefsQuery, import('./api').TransactionCaseExternalRefsQueryVariables>(Operations.TransactionCaseExternalRefs, options);
      }
export function useTransactionCaseExternalRefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionCaseExternalRefsQuery, import('./api').TransactionCaseExternalRefsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionCaseExternalRefsQuery, import('./api').TransactionCaseExternalRefsQueryVariables>(Operations.TransactionCaseExternalRefs, options);
        }
export type TransactionCaseExternalRefsQueryHookResult = ReturnType<typeof useTransactionCaseExternalRefsQuery>;
export type TransactionCaseExternalRefsLazyQueryHookResult = ReturnType<typeof useTransactionCaseExternalRefsLazyQuery>;
export function useTransactionDictionariesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionDictionariesQuery, import('./api').TransactionDictionariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionDictionariesQuery, import('./api').TransactionDictionariesQueryVariables>(Operations.TransactionDictionaries, options);
      }
export function useTransactionDictionariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionDictionariesQuery, import('./api').TransactionDictionariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionDictionariesQuery, import('./api').TransactionDictionariesQueryVariables>(Operations.TransactionDictionaries, options);
        }
export type TransactionDictionariesQueryHookResult = ReturnType<typeof useTransactionDictionariesQuery>;
export type TransactionDictionariesLazyQueryHookResult = ReturnType<typeof useTransactionDictionariesLazyQuery>;
export function useTransactionDispositionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionDispositionsQuery, import('./api').TransactionDispositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionDispositionsQuery, import('./api').TransactionDispositionsQueryVariables>(Operations.TransactionDispositions, options);
      }
export function useTransactionDispositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionDispositionsQuery, import('./api').TransactionDispositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionDispositionsQuery, import('./api').TransactionDispositionsQueryVariables>(Operations.TransactionDispositions, options);
        }
export type TransactionDispositionsQueryHookResult = ReturnType<typeof useTransactionDispositionsQuery>;
export type TransactionDispositionsLazyQueryHookResult = ReturnType<typeof useTransactionDispositionsLazyQuery>;
export function useDispositionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').DispositionsQuery, import('./api').DispositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').DispositionsQuery, import('./api').DispositionsQueryVariables>(Operations.Dispositions, options);
      }
export function useDispositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').DispositionsQuery, import('./api').DispositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').DispositionsQuery, import('./api').DispositionsQueryVariables>(Operations.Dispositions, options);
        }
export type DispositionsQueryHookResult = ReturnType<typeof useDispositionsQuery>;
export type DispositionsLazyQueryHookResult = ReturnType<typeof useDispositionsLazyQuery>;
export function useTransactionEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionEntitiesQuery, import('./api').TransactionEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionEntitiesQuery, import('./api').TransactionEntitiesQueryVariables>(Operations.TransactionEntities, options);
      }
export function useTransactionEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionEntitiesQuery, import('./api').TransactionEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionEntitiesQuery, import('./api').TransactionEntitiesQueryVariables>(Operations.TransactionEntities, options);
        }
export type TransactionEntitiesQueryHookResult = ReturnType<typeof useTransactionEntitiesQuery>;
export type TransactionEntitiesLazyQueryHookResult = ReturnType<typeof useTransactionEntitiesLazyQuery>;
export function useTransactionEntityQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionEntityQuery, import('./api').TransactionEntityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionEntityQuery, import('./api').TransactionEntityQueryVariables>(Operations.TransactionEntity, options);
      }
export function useTransactionEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionEntityQuery, import('./api').TransactionEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionEntityQuery, import('./api').TransactionEntityQueryVariables>(Operations.TransactionEntity, options);
        }
export type TransactionEntityQueryHookResult = ReturnType<typeof useTransactionEntityQuery>;
export type TransactionEntityLazyQueryHookResult = ReturnType<typeof useTransactionEntityLazyQuery>;
export function useTransactionEventsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionEventsQuery, import('./api').TransactionEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionEventsQuery, import('./api').TransactionEventsQueryVariables>(Operations.TransactionEvents, options);
      }
export function useTransactionEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionEventsQuery, import('./api').TransactionEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionEventsQuery, import('./api').TransactionEventsQueryVariables>(Operations.TransactionEvents, options);
        }
export type TransactionEventsQueryHookResult = ReturnType<typeof useTransactionEventsQuery>;
export type TransactionEventsLazyQueryHookResult = ReturnType<typeof useTransactionEventsLazyQuery>;
export function useTransactionPagginationEventsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionPagginationEventsQuery, import('./api').TransactionPagginationEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionPagginationEventsQuery, import('./api').TransactionPagginationEventsQueryVariables>(Operations.TransactionPagginationEvents, options);
      }
export function useTransactionPagginationEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionPagginationEventsQuery, import('./api').TransactionPagginationEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionPagginationEventsQuery, import('./api').TransactionPagginationEventsQueryVariables>(Operations.TransactionPagginationEvents, options);
        }
export type TransactionPagginationEventsQueryHookResult = ReturnType<typeof useTransactionPagginationEventsQuery>;
export type TransactionPagginationEventsLazyQueryHookResult = ReturnType<typeof useTransactionPagginationEventsLazyQuery>;
export function useTransactionEventQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionEventQuery, import('./api').TransactionEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionEventQuery, import('./api').TransactionEventQueryVariables>(Operations.TransactionEvent, options);
      }
export function useTransactionEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionEventQuery, import('./api').TransactionEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionEventQuery, import('./api').TransactionEventQueryVariables>(Operations.TransactionEvent, options);
        }
export type TransactionEventQueryHookResult = ReturnType<typeof useTransactionEventQuery>;
export type TransactionEventLazyQueryHookResult = ReturnType<typeof useTransactionEventLazyQuery>;
export function useTransactionFilterGroupQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionFilterGroupQuery, import('./api').TransactionFilterGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionFilterGroupQuery, import('./api').TransactionFilterGroupQueryVariables>(Operations.TransactionFilterGroup, options);
      }
export function useTransactionFilterGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionFilterGroupQuery, import('./api').TransactionFilterGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionFilterGroupQuery, import('./api').TransactionFilterGroupQueryVariables>(Operations.TransactionFilterGroup, options);
        }
export type TransactionFilterGroupQueryHookResult = ReturnType<typeof useTransactionFilterGroupQuery>;
export type TransactionFilterGroupLazyQueryHookResult = ReturnType<typeof useTransactionFilterGroupLazyQuery>;
export function useTransactionInstrumentsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionInstrumentsQuery, import('./api').TransactionInstrumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionInstrumentsQuery, import('./api').TransactionInstrumentsQueryVariables>(Operations.TransactionInstruments, options);
      }
export function useTransactionInstrumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionInstrumentsQuery, import('./api').TransactionInstrumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionInstrumentsQuery, import('./api').TransactionInstrumentsQueryVariables>(Operations.TransactionInstruments, options);
        }
export type TransactionInstrumentsQueryHookResult = ReturnType<typeof useTransactionInstrumentsQuery>;
export type TransactionInstrumentsLazyQueryHookResult = ReturnType<typeof useTransactionInstrumentsLazyQuery>;
export function useTransactionInstrumentQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionInstrumentQuery, import('./api').TransactionInstrumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionInstrumentQuery, import('./api').TransactionInstrumentQueryVariables>(Operations.TransactionInstrument, options);
      }
export function useTransactionInstrumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionInstrumentQuery, import('./api').TransactionInstrumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionInstrumentQuery, import('./api').TransactionInstrumentQueryVariables>(Operations.TransactionInstrument, options);
        }
export type TransactionInstrumentQueryHookResult = ReturnType<typeof useTransactionInstrumentQuery>;
export type TransactionInstrumentLazyQueryHookResult = ReturnType<typeof useTransactionInstrumentLazyQuery>;
export function useNarrativeTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').NarrativeTemplatesQuery, import('./api').NarrativeTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').NarrativeTemplatesQuery, import('./api').NarrativeTemplatesQueryVariables>(Operations.NarrativeTemplates, options);
      }
export function useNarrativeTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').NarrativeTemplatesQuery, import('./api').NarrativeTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').NarrativeTemplatesQuery, import('./api').NarrativeTemplatesQueryVariables>(Operations.NarrativeTemplates, options);
        }
export type NarrativeTemplatesQueryHookResult = ReturnType<typeof useNarrativeTemplatesQuery>;
export type NarrativeTemplatesLazyQueryHookResult = ReturnType<typeof useNarrativeTemplatesLazyQuery>;
export function useNarrativeTemplatesQueuedQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').NarrativeTemplatesQueuedQuery, import('./api').NarrativeTemplatesQueuedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').NarrativeTemplatesQueuedQuery, import('./api').NarrativeTemplatesQueuedQueryVariables>(Operations.NarrativeTemplatesQueued, options);
      }
export function useNarrativeTemplatesQueuedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').NarrativeTemplatesQueuedQuery, import('./api').NarrativeTemplatesQueuedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').NarrativeTemplatesQueuedQuery, import('./api').NarrativeTemplatesQueuedQueryVariables>(Operations.NarrativeTemplatesQueued, options);
        }
export type NarrativeTemplatesQueuedQueryHookResult = ReturnType<typeof useNarrativeTemplatesQueuedQuery>;
export type NarrativeTemplatesQueuedLazyQueryHookResult = ReturnType<typeof useNarrativeTemplatesQueuedLazyQuery>;
export function useNarrativesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').NarrativesQuery, import('./api').NarrativesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').NarrativesQuery, import('./api').NarrativesQueryVariables>(Operations.Narratives, options);
      }
export function useNarrativesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').NarrativesQuery, import('./api').NarrativesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').NarrativesQuery, import('./api').NarrativesQueryVariables>(Operations.Narratives, options);
        }
export type NarrativesQueryHookResult = ReturnType<typeof useNarrativesQuery>;
export type NarrativesLazyQueryHookResult = ReturnType<typeof useNarrativesLazyQuery>;
export function useNarrativeTemplateQuery(baseOptions: Apollo.QueryHookOptions<import('./api').NarrativeTemplateQuery, import('./api').NarrativeTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').NarrativeTemplateQuery, import('./api').NarrativeTemplateQueryVariables>(Operations.NarrativeTemplate, options);
      }
export function useNarrativeTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').NarrativeTemplateQuery, import('./api').NarrativeTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').NarrativeTemplateQuery, import('./api').NarrativeTemplateQueryVariables>(Operations.NarrativeTemplate, options);
        }
export type NarrativeTemplateQueryHookResult = ReturnType<typeof useNarrativeTemplateQuery>;
export type NarrativeTemplateLazyQueryHookResult = ReturnType<typeof useNarrativeTemplateLazyQuery>;
export function useTransactionProvidersQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionProvidersQuery, import('./api').TransactionProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionProvidersQuery, import('./api').TransactionProvidersQueryVariables>(Operations.TransactionProviders, options);
      }
export function useTransactionProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionProvidersQuery, import('./api').TransactionProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionProvidersQuery, import('./api').TransactionProvidersQueryVariables>(Operations.TransactionProviders, options);
        }
export type TransactionProvidersQueryHookResult = ReturnType<typeof useTransactionProvidersQuery>;
export type TransactionProvidersLazyQueryHookResult = ReturnType<typeof useTransactionProvidersLazyQuery>;
export function useTransactionQueuesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionQueuesQuery, import('./api').TransactionQueuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionQueuesQuery, import('./api').TransactionQueuesQueryVariables>(Operations.TransactionQueues, options);
      }
export function useTransactionQueuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionQueuesQuery, import('./api').TransactionQueuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionQueuesQuery, import('./api').TransactionQueuesQueryVariables>(Operations.TransactionQueues, options);
        }
export type TransactionQueuesQueryHookResult = ReturnType<typeof useTransactionQueuesQuery>;
export type TransactionQueuesLazyQueryHookResult = ReturnType<typeof useTransactionQueuesLazyQuery>;
export function useTransactionResolutionsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionResolutionsQuery, import('./api').TransactionResolutionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionResolutionsQuery, import('./api').TransactionResolutionsQueryVariables>(Operations.TransactionResolutions, options);
      }
export function useTransactionResolutionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionResolutionsQuery, import('./api').TransactionResolutionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionResolutionsQuery, import('./api').TransactionResolutionsQueryVariables>(Operations.TransactionResolutions, options);
        }
export type TransactionResolutionsQueryHookResult = ReturnType<typeof useTransactionResolutionsQuery>;
export type TransactionResolutionsLazyQueryHookResult = ReturnType<typeof useTransactionResolutionsLazyQuery>;
export function useTransactionEventRfiItemsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionEventRfiItemsQuery, import('./api').TransactionEventRfiItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionEventRfiItemsQuery, import('./api').TransactionEventRfiItemsQueryVariables>(Operations.TransactionEventRfiItems, options);
      }
export function useTransactionEventRfiItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionEventRfiItemsQuery, import('./api').TransactionEventRfiItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionEventRfiItemsQuery, import('./api').TransactionEventRfiItemsQueryVariables>(Operations.TransactionEventRfiItems, options);
        }
export type TransactionEventRfiItemsQueryHookResult = ReturnType<typeof useTransactionEventRfiItemsQuery>;
export type TransactionEventRfiItemsLazyQueryHookResult = ReturnType<typeof useTransactionEventRfiItemsLazyQuery>;
export function useTransactionRuleTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionRuleTemplatesQuery, import('./api').TransactionRuleTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionRuleTemplatesQuery, import('./api').TransactionRuleTemplatesQueryVariables>(Operations.TransactionRuleTemplates, options);
      }
export function useTransactionRuleTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionRuleTemplatesQuery, import('./api').TransactionRuleTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionRuleTemplatesQuery, import('./api').TransactionRuleTemplatesQueryVariables>(Operations.TransactionRuleTemplates, options);
        }
export type TransactionRuleTemplatesQueryHookResult = ReturnType<typeof useTransactionRuleTemplatesQuery>;
export type TransactionRuleTemplatesLazyQueryHookResult = ReturnType<typeof useTransactionRuleTemplatesLazyQuery>;
export function useTransactionRuleQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionRuleQuery, import('./api').TransactionRuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionRuleQuery, import('./api').TransactionRuleQueryVariables>(Operations.TransactionRule, options);
      }
export function useTransactionRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionRuleQuery, import('./api').TransactionRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionRuleQuery, import('./api').TransactionRuleQueryVariables>(Operations.TransactionRule, options);
        }
export type TransactionRuleQueryHookResult = ReturnType<typeof useTransactionRuleQuery>;
export type TransactionRuleLazyQueryHookResult = ReturnType<typeof useTransactionRuleLazyQuery>;
export function useTransactionRulesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionRulesQuery, import('./api').TransactionRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionRulesQuery, import('./api').TransactionRulesQueryVariables>(Operations.TransactionRules, options);
      }
export function useTransactionRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionRulesQuery, import('./api').TransactionRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionRulesQuery, import('./api').TransactionRulesQueryVariables>(Operations.TransactionRules, options);
        }
export type TransactionRulesQueryHookResult = ReturnType<typeof useTransactionRulesQuery>;
export type TransactionRulesLazyQueryHookResult = ReturnType<typeof useTransactionRulesLazyQuery>;
export function useTransactionRuleResultsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionRuleResultsQuery, import('./api').TransactionRuleResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionRuleResultsQuery, import('./api').TransactionRuleResultsQueryVariables>(Operations.TransactionRuleResults, options);
      }
export function useTransactionRuleResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionRuleResultsQuery, import('./api').TransactionRuleResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionRuleResultsQuery, import('./api').TransactionRuleResultsQueryVariables>(Operations.TransactionRuleResults, options);
        }
export type TransactionRuleResultsQueryHookResult = ReturnType<typeof useTransactionRuleResultsQuery>;
export type TransactionRuleResultsLazyQueryHookResult = ReturnType<typeof useTransactionRuleResultsLazyQuery>;
export function useTransactionRuleFlaggedCountsQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionRuleFlaggedCountsQuery, import('./api').TransactionRuleFlaggedCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionRuleFlaggedCountsQuery, import('./api').TransactionRuleFlaggedCountsQueryVariables>(Operations.TransactionRuleFlaggedCounts, options);
      }
export function useTransactionRuleFlaggedCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionRuleFlaggedCountsQuery, import('./api').TransactionRuleFlaggedCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionRuleFlaggedCountsQuery, import('./api').TransactionRuleFlaggedCountsQueryVariables>(Operations.TransactionRuleFlaggedCounts, options);
        }
export type TransactionRuleFlaggedCountsQueryHookResult = ReturnType<typeof useTransactionRuleFlaggedCountsQuery>;
export type TransactionRuleFlaggedCountsLazyQueryHookResult = ReturnType<typeof useTransactionRuleFlaggedCountsLazyQuery>;
export function useTransactionRuleSetsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionRuleSetsQuery, import('./api').TransactionRuleSetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionRuleSetsQuery, import('./api').TransactionRuleSetsQueryVariables>(Operations.TransactionRuleSets, options);
      }
export function useTransactionRuleSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionRuleSetsQuery, import('./api').TransactionRuleSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionRuleSetsQuery, import('./api').TransactionRuleSetsQueryVariables>(Operations.TransactionRuleSets, options);
        }
export type TransactionRuleSetsQueryHookResult = ReturnType<typeof useTransactionRuleSetsQuery>;
export type TransactionRuleSetsLazyQueryHookResult = ReturnType<typeof useTransactionRuleSetsLazyQuery>;
export function useTransactionRuleSetQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionRuleSetQuery, import('./api').TransactionRuleSetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionRuleSetQuery, import('./api').TransactionRuleSetQueryVariables>(Operations.TransactionRuleSet, options);
      }
export function useTransactionRuleSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionRuleSetQuery, import('./api').TransactionRuleSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionRuleSetQuery, import('./api').TransactionRuleSetQueryVariables>(Operations.TransactionRuleSet, options);
        }
export type TransactionRuleSetQueryHookResult = ReturnType<typeof useTransactionRuleSetQuery>;
export type TransactionRuleSetLazyQueryHookResult = ReturnType<typeof useTransactionRuleSetLazyQuery>;
export function useTransactionRuleImportanceQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionRuleImportanceQuery, import('./api').TransactionRuleImportanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionRuleImportanceQuery, import('./api').TransactionRuleImportanceQueryVariables>(Operations.TransactionRuleImportance, options);
      }
export function useTransactionRuleImportanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionRuleImportanceQuery, import('./api').TransactionRuleImportanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionRuleImportanceQuery, import('./api').TransactionRuleImportanceQueryVariables>(Operations.TransactionRuleImportance, options);
        }
export type TransactionRuleImportanceQueryHookResult = ReturnType<typeof useTransactionRuleImportanceQuery>;
export type TransactionRuleImportanceLazyQueryHookResult = ReturnType<typeof useTransactionRuleImportanceLazyQuery>;
export function useTransactionScoringDependentRulesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionScoringDependentRulesQuery, import('./api').TransactionScoringDependentRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionScoringDependentRulesQuery, import('./api').TransactionScoringDependentRulesQueryVariables>(Operations.TransactionScoringDependentRules, options);
      }
export function useTransactionScoringDependentRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionScoringDependentRulesQuery, import('./api').TransactionScoringDependentRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionScoringDependentRulesQuery, import('./api').TransactionScoringDependentRulesQueryVariables>(Operations.TransactionScoringDependentRules, options);
        }
export type TransactionScoringDependentRulesQueryHookResult = ReturnType<typeof useTransactionScoringDependentRulesQuery>;
export type TransactionScoringDependentRulesLazyQueryHookResult = ReturnType<typeof useTransactionScoringDependentRulesLazyQuery>;
export function useTransactionScoringManualRulesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionScoringManualRulesQuery, import('./api').TransactionScoringManualRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionScoringManualRulesQuery, import('./api').TransactionScoringManualRulesQueryVariables>(Operations.TransactionScoringManualRules, options);
      }
export function useTransactionScoringManualRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionScoringManualRulesQuery, import('./api').TransactionScoringManualRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionScoringManualRulesQuery, import('./api').TransactionScoringManualRulesQueryVariables>(Operations.TransactionScoringManualRules, options);
        }
export type TransactionScoringManualRulesQueryHookResult = ReturnType<typeof useTransactionScoringManualRulesQuery>;
export type TransactionScoringManualRulesLazyQueryHookResult = ReturnType<typeof useTransactionScoringManualRulesLazyQuery>;
export function useTransactionScoringMatricesQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionScoringMatricesQuery, import('./api').TransactionScoringMatricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionScoringMatricesQuery, import('./api').TransactionScoringMatricesQueryVariables>(Operations.TransactionScoringMatrices, options);
      }
export function useTransactionScoringMatricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionScoringMatricesQuery, import('./api').TransactionScoringMatricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionScoringMatricesQuery, import('./api').TransactionScoringMatricesQueryVariables>(Operations.TransactionScoringMatrices, options);
        }
export type TransactionScoringMatricesQueryHookResult = ReturnType<typeof useTransactionScoringMatricesQuery>;
export type TransactionScoringMatricesLazyQueryHookResult = ReturnType<typeof useTransactionScoringMatricesLazyQuery>;
export function useTransactionScoringMatrixQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionScoringMatrixQuery, import('./api').TransactionScoringMatrixQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionScoringMatrixQuery, import('./api').TransactionScoringMatrixQueryVariables>(Operations.TransactionScoringMatrix, options);
      }
export function useTransactionScoringMatrixLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionScoringMatrixQuery, import('./api').TransactionScoringMatrixQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionScoringMatrixQuery, import('./api').TransactionScoringMatrixQueryVariables>(Operations.TransactionScoringMatrix, options);
        }
export type TransactionScoringMatrixQueryHookResult = ReturnType<typeof useTransactionScoringMatrixQuery>;
export type TransactionScoringMatrixLazyQueryHookResult = ReturnType<typeof useTransactionScoringMatrixLazyQuery>;
export function useEventResultMatricesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').EventResultMatricesQuery, import('./api').EventResultMatricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').EventResultMatricesQuery, import('./api').EventResultMatricesQueryVariables>(Operations.EventResultMatrices, options);
      }
export function useEventResultMatricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').EventResultMatricesQuery, import('./api').EventResultMatricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').EventResultMatricesQuery, import('./api').EventResultMatricesQueryVariables>(Operations.EventResultMatrices, options);
        }
export type EventResultMatricesQueryHookResult = ReturnType<typeof useEventResultMatricesQuery>;
export type EventResultMatricesLazyQueryHookResult = ReturnType<typeof useEventResultMatricesLazyQuery>;
export function useResultMatrixQuery(baseOptions: Apollo.QueryHookOptions<import('./api').ResultMatrixQuery, import('./api').ResultMatrixQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').ResultMatrixQuery, import('./api').ResultMatrixQueryVariables>(Operations.ResultMatrix, options);
      }
export function useResultMatrixLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').ResultMatrixQuery, import('./api').ResultMatrixQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').ResultMatrixQuery, import('./api').ResultMatrixQueryVariables>(Operations.ResultMatrix, options);
        }
export type ResultMatrixQueryHookResult = ReturnType<typeof useResultMatrixQuery>;
export type ResultMatrixLazyQueryHookResult = ReturnType<typeof useResultMatrixLazyQuery>;
export function useTransactionScoringRulesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionScoringRulesQuery, import('./api').TransactionScoringRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionScoringRulesQuery, import('./api').TransactionScoringRulesQueryVariables>(Operations.TransactionScoringRules, options);
      }
export function useTransactionScoringRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionScoringRulesQuery, import('./api').TransactionScoringRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionScoringRulesQuery, import('./api').TransactionScoringRulesQueryVariables>(Operations.TransactionScoringRules, options);
        }
export type TransactionScoringRulesQueryHookResult = ReturnType<typeof useTransactionScoringRulesQuery>;
export type TransactionScoringRulesLazyQueryHookResult = ReturnType<typeof useTransactionScoringRulesLazyQuery>;
export function useTransactionScoringScoreRulesQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionScoringScoreRulesQuery, import('./api').TransactionScoringScoreRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionScoringScoreRulesQuery, import('./api').TransactionScoringScoreRulesQueryVariables>(Operations.TransactionScoringScoreRules, options);
      }
export function useTransactionScoringScoreRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionScoringScoreRulesQuery, import('./api').TransactionScoringScoreRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionScoringScoreRulesQuery, import('./api').TransactionScoringScoreRulesQueryVariables>(Operations.TransactionScoringScoreRules, options);
        }
export type TransactionScoringScoreRulesQueryHookResult = ReturnType<typeof useTransactionScoringScoreRulesQuery>;
export type TransactionScoringScoreRulesLazyQueryHookResult = ReturnType<typeof useTransactionScoringScoreRulesLazyQuery>;
export function useTransactionStatsEventsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionStatsEventsQuery, import('./api').TransactionStatsEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionStatsEventsQuery, import('./api').TransactionStatsEventsQueryVariables>(Operations.TransactionStatsEvents, options);
      }
export function useTransactionStatsEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionStatsEventsQuery, import('./api').TransactionStatsEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionStatsEventsQuery, import('./api').TransactionStatsEventsQueryVariables>(Operations.TransactionStatsEvents, options);
        }
export type TransactionStatsEventsQueryHookResult = ReturnType<typeof useTransactionStatsEventsQuery>;
export type TransactionStatsEventsLazyQueryHookResult = ReturnType<typeof useTransactionStatsEventsLazyQuery>;
export function useTransactionStatsMonthsEventsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionStatsMonthsEventsQuery, import('./api').TransactionStatsMonthsEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionStatsMonthsEventsQuery, import('./api').TransactionStatsMonthsEventsQueryVariables>(Operations.TransactionStatsMonthsEvents, options);
      }
export function useTransactionStatsMonthsEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionStatsMonthsEventsQuery, import('./api').TransactionStatsMonthsEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionStatsMonthsEventsQuery, import('./api').TransactionStatsMonthsEventsQueryVariables>(Operations.TransactionStatsMonthsEvents, options);
        }
export type TransactionStatsMonthsEventsQueryHookResult = ReturnType<typeof useTransactionStatsMonthsEventsQuery>;
export type TransactionStatsMonthsEventsLazyQueryHookResult = ReturnType<typeof useTransactionStatsMonthsEventsLazyQuery>;
export function useTransactionTagsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').TransactionTagsQuery, import('./api').TransactionTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionTagsQuery, import('./api').TransactionTagsQueryVariables>(Operations.TransactionTags, options);
      }
export function useTransactionTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionTagsQuery, import('./api').TransactionTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionTagsQuery, import('./api').TransactionTagsQueryVariables>(Operations.TransactionTags, options);
        }
export type TransactionTagsQueryHookResult = ReturnType<typeof useTransactionTagsQuery>;
export type TransactionTagsLazyQueryHookResult = ReturnType<typeof useTransactionTagsLazyQuery>;
export function useTransactionTagQuery(baseOptions: Apollo.QueryHookOptions<import('./api').TransactionTagQuery, import('./api').TransactionTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').TransactionTagQuery, import('./api').TransactionTagQueryVariables>(Operations.TransactionTag, options);
      }
export function useTransactionTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').TransactionTagQuery, import('./api').TransactionTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').TransactionTagQuery, import('./api').TransactionTagQueryVariables>(Operations.TransactionTag, options);
        }
export type TransactionTagQueryHookResult = ReturnType<typeof useTransactionTagQuery>;
export type TransactionTagLazyQueryHookResult = ReturnType<typeof useTransactionTagLazyQuery>;
export function useCustomerVerificationsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CustomerVerificationsQuery, import('./api').CustomerVerificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CustomerVerificationsQuery, import('./api').CustomerVerificationsQueryVariables>(Operations.CustomerVerifications, options);
      }
export function useCustomerVerificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CustomerVerificationsQuery, import('./api').CustomerVerificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CustomerVerificationsQuery, import('./api').CustomerVerificationsQueryVariables>(Operations.CustomerVerifications, options);
        }
export type CustomerVerificationsQueryHookResult = ReturnType<typeof useCustomerVerificationsQuery>;
export type CustomerVerificationsLazyQueryHookResult = ReturnType<typeof useCustomerVerificationsLazyQuery>;
export function useCustomerVerificationOnBoardingQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').CustomerVerificationOnBoardingQuery, import('./api').CustomerVerificationOnBoardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CustomerVerificationOnBoardingQuery, import('./api').CustomerVerificationOnBoardingQueryVariables>(Operations.CustomerVerificationOnBoarding, options);
      }
export function useCustomerVerificationOnBoardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CustomerVerificationOnBoardingQuery, import('./api').CustomerVerificationOnBoardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CustomerVerificationOnBoardingQuery, import('./api').CustomerVerificationOnBoardingQueryVariables>(Operations.CustomerVerificationOnBoarding, options);
        }
export type CustomerVerificationOnBoardingQueryHookResult = ReturnType<typeof useCustomerVerificationOnBoardingQuery>;
export type CustomerVerificationOnBoardingLazyQueryHookResult = ReturnType<typeof useCustomerVerificationOnBoardingLazyQuery>;
export function useCustomerVerificationUrlQuery(baseOptions: Apollo.QueryHookOptions<import('./api').CustomerVerificationUrlQuery, import('./api').CustomerVerificationUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').CustomerVerificationUrlQuery, import('./api').CustomerVerificationUrlQueryVariables>(Operations.CustomerVerificationUrl, options);
      }
export function useCustomerVerificationUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').CustomerVerificationUrlQuery, import('./api').CustomerVerificationUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').CustomerVerificationUrlQuery, import('./api').CustomerVerificationUrlQueryVariables>(Operations.CustomerVerificationUrl, options);
        }
export type CustomerVerificationUrlQueryHookResult = ReturnType<typeof useCustomerVerificationUrlQuery>;
export type CustomerVerificationUrlLazyQueryHookResult = ReturnType<typeof useCustomerVerificationUrlLazyQuery>;
export function useVerificationFlowsQuery(baseOptions?: Apollo.QueryHookOptions<import('./api').VerificationFlowsQuery, import('./api').VerificationFlowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').VerificationFlowsQuery, import('./api').VerificationFlowsQueryVariables>(Operations.VerificationFlows, options);
      }
export function useVerificationFlowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').VerificationFlowsQuery, import('./api').VerificationFlowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').VerificationFlowsQuery, import('./api').VerificationFlowsQueryVariables>(Operations.VerificationFlows, options);
        }
export type VerificationFlowsQueryHookResult = ReturnType<typeof useVerificationFlowsQuery>;
export type VerificationFlowsLazyQueryHookResult = ReturnType<typeof useVerificationFlowsLazyQuery>;
export function useVerificationFlowQuery(baseOptions: Apollo.QueryHookOptions<import('./api').VerificationFlowQuery, import('./api').VerificationFlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<import('./api').VerificationFlowQuery, import('./api').VerificationFlowQueryVariables>(Operations.VerificationFlow, options);
      }
export function useVerificationFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<import('./api').VerificationFlowQuery, import('./api').VerificationFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<import('./api').VerificationFlowQuery, import('./api').VerificationFlowQueryVariables>(Operations.VerificationFlow, options);
        }
export type VerificationFlowQueryHookResult = ReturnType<typeof useVerificationFlowQuery>;
export type VerificationFlowLazyQueryHookResult = ReturnType<typeof useVerificationFlowLazyQuery>;